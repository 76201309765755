/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

/* BOTON TRANSPARENTE Y SIN BORDE */
.icon-button {
  background-color: inherit;
  border: 0;
}

.custom-md-tabs-header {
  margin-left: unset !important;
  margin-right: unset !important;
}

/**
  Tablas
**/
table.table th, table.table:not(.inline-buttons-table) td {
  padding: 0.4rem !important;
}

.table-cell-save{
  border:none !important;
}

/**TABLA CON BOTONES INLINE**/
.inline-buttons-table {
  border-collapse: collapse;
  width: 100%;
  border: 2px solid #9fa0ae;

  table-layout: fixed; /* Fijamos el ancho de la tabla */

  //Celdas Cabecera
  th {
    border: 1px solid #9fa0ae !important;
    background-color: #f2f2f2 !important;
    font-weight: bold !important;
        &.buttons-column {
          width: 7% !important; /* Un ancho relativo a dos caracteres (5 caracteres x 2) */
          text-align: center !important;
          padding: 0.25rem 0.5rem !important;
        }

        &.icon-column {
          width: 5%;
          text-align: center !important;
          padding: 0.25rem 0.5rem !important;
        }

        &.icon-column-small {
          width: 2%;
          text-align: center !important;
          padding: 0.25rem 0.5rem !important;
        }

        &.numbers-column {
          width: 5%;
        }
        &.percent-column {
          width: 4%;
        }

        &.code-column {
          width: 8%;
        }

        &.medium-column {
          width: 15%;
        }

        &.large-column {
          width: 20%;
        }

        @media screen and (max-width: 1800px) {
          &.buttons-column {
            width: 8% !important;
          }
          &.code-column {
            width: 10%;
          }
          &.medium-column {
            width: 16%;
          }
          &.numbers-column {
            width: 6%;
          }
        }

        @media screen and (max-width: 1300px) {
          &.buttons-column {
            width: 12% !important;
          }

          &.medium-column {
            width: 25%;
          }

          &.icon-column {
            width: 7%;
          }

          &.numbers-column {
            width: 8%;
          }
        }


/*
@media screen and (max-width: 1600px) {
  .excel-table th.buttons-column {
    width: 2%;
  }

  .excel-table th.numbers-column {
    width: 7%;
  }

  .excel-table th.code-column {
    width: 8%;
  }

  .excel-table th.medium-column {
    width: 18%;
  }
}
*/


  }

  //Celdas Datos
  td {
    border: 1px solid #9fa0ae;
    padding: 0 !important;


        > mdb-checkbox {
          text-align: center;
        }

        > mdb-icon {
          vertical-align: bottom;
        }



        > div.icono-input {
          display: flex;
          align-items: center;
          input {
            margin-left: 5px;
            flex-grow: 1; /* Permite al input crecer para ocupar el espacio restante */

          }
          select {
            flex-grow: 1;

          }
        }

        > span {
          padding: 0.25rem 0.5rem;
          font-size: 0.875rem;
          display: block;
          font-weight: 400;
        }
        &.buttons-column {
          width: 7%; /* Un ancho relativo a dos caracteres (5 caracteres x 2) */
          text-align: center;
          padding: 0.25rem 0.5rem !important;

          >.form-check-input[type=radio] + label {
            height: 1.2625rem;
            padding-left: 25px;
          }
        }

        &.icon-column {
          width: 5%;
          text-align: center !important;
          padding: 0.25rem 0.5rem !important;
          &:has(mdb-icon):not(:has(a)) {
            padding: unset !important;
          }
        }

        &.icon-column-small {
          width: 2%;
          text-align: center !important;
          padding: 0.25rem 0.5rem !important;
        }

        mdb-error{
          top: unset;
          left: unset;
          position: relative;
        }
  }

  //INPUTS
  input[type="text"],
  input[type="number"]{
    // width: calc(100% - 6px); /* Restamos el padding y el borde para que el input ocupe toda la celda */
    //  height: 100%;
    // padding: 2px !important; /* Añadimos un pequeño padding al input para mejorar la apariencia */
      border: none; /* Eliminamos el borde del input */
      outline: none; /* Eliminamos el contorno al enfocar el input */
      box-sizing: border-box; /* El ancho incluirá el padding y el borde */
      background-color: inherit;
  }

  //COMBO
  select {
    // width: calc(100% - 6px); /* Restamos el padding y el borde para que el select ocupe toda la celda */
     height: 100%;
    // padding: 2px !important; /* Añadimos un pequeño padding al select para mejorar la apariencia */
     border: none; /* Eliminamos el borde del select */
     outline: none; /* Eliminamos el contorno al enfocar el select */
     box-sizing: border-box; /* El ancho incluirá el padding y el borde */

     background-color: inherit;

     &:focus{
      background-color: inherit;
     }

   }

}

/**PARA LOS FORMULARIOS DE ALTA**/

.divCustomGlobal{
  overflow-x: hidden;
}

.divCustomSection{
  margin: unset;
  padding: unset;
  background-color: #eeeeee;
  color: gray;
  font-weight: 500;
  padding: 10px 24px;
}

/**TABSET PERSONALIZADO***/
.custom-tabset{
  & .container-fluid{
    margin: unset;
    padding: unset;
    & .row  {
      margin: unset;
      & .col-md-12 {
        margin: unset;
        padding: unset;
        &  div.tab-content {
          margin: unset;
            box-shadow: none;
        }
        & ul.classic-tabs.secondTab {
          background-color: #04927f !important;
          & li.nav-item {
            margin: unset;
            & a {
              padding: 10px 24px;
            }
            & a.nav-link {
              border-bottom: 3px solid black;
              color: black;
              font-weight: 500;
            }
            & a.nav-link.active {
              border-bottom: 4px solid white;
              color: white;
              font-weight: 500;
              /*border-color: gray;
              color: gray;
              font-weight: 500;*/
            }
          }
          & li:not(.nav-item) {
            margin: unset;
            & a {
              padding: 10px 24px;
              border-color: black;
              color: black;
              font-weight: 500;
            }
          }

        }
        & ul.classic-tabs {
          border-radius: unset;
          margin: unset;
          background-color: #EEEEEE !important;

          & li.nav-item {
            margin: unset;
            & a {
              padding: 10px 24px;
            }
            & a.nav-link {
              border-bottom: 3px solid #fff;
              color: gray;
              font-weight: 500;
            }
            & a.nav-link.active {
              border-bottom: 4px solid #2196F3;
              color: gray;
              font-weight: 500;
              /*border-color: gray;
              color: gray;
              font-weight: 500;*/
            }
          }
          & li:not(.nav-item) {
            margin: unset;
            & a {
              padding: 10px 24px;
              border-color: #424242;
              color: #424242;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}


/**
PESTAÑAS APP
*/
//Pestañas No Seleccionadas
mat-tab-group.app-tabs .mat-mdc-tab.mat-mdc-focus-indicator{
  background-color: #9e9e9e4d;

}
//Label de Pestañas No seleccionadas
mat-tab-group.app-tabs .mat-mdc-tab.mat-mdc-focus-indicator > * > .mdc-tab__text-label {
  color: #666;
  font-weight: normal;
}

//Pestañas no seleccionadas y modificadas
mat-tab-group.app-tabs .mat-mdc-tab.mat-mdc-focus-indicator:not(.mdc-tab-indicator--active):has(.edit-tab){
  background-color: #ffecb3!important;
}


//Pestaña Activa
mat-tab-group.app-tabs .mat-mdc-tab.mdc-tab-indicator--active{
  //background-color: #4285f4!important;
  background-color: #2196f3 !important;

  opacity: 1 !important;
  //box-shadow: 2px 2px 3px 1px #373a3a;
  //font-weight: .95rem;
  //box-shadow: 1px 10px lightskyblue;

  & > * > .mdc-tab__text-label {
    color: #fff !important;
    font-weight: normal;
    transition: none;
  }

}

.mdc-tab {
  letter-spacing: normal;
}


/***/
/* .has-register {
  background-color: #fee8bb !important;
} */

/**
  Formularios
**/
.md-form.md-outline{
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
}
.md-form{
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
}

/**
NOTIFICACION
*/
.opacidadWarning {
  opacity: 0.8 !important;
}

/**
STEPPER
**/

:host ::ng-deep .card-body ul.stepper.horizontal .step.done .step-title:before {
  font-family: unset !important;
}
.step-title:before {
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free" !important;
}

/**
BADGES
*/
//Se usará para mostrar badges en las cabeceras de las pestañas: se usará junto a las clases de colores
.tab-badge {
  box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
    //border-radius: 0.125rem;
    color: #fff!important;

//	background-color: #00bcd4 !important;
	display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//colores distintos para la clase tab-badge
.badgeinfo {
  background-color: #00bcd4 !important;
}


/**
FORMULARIOS
*/
.form-control{
  //background-color: #ececec !important;
  color: grey !important;
}

.form-control:disabled, .form-control[readonly]{
 // pointer-events: unset !important;
  cursor: not-allowed !important;
}
mdb-select .single {
  font-weight: 400 !important;
  font-size: 0.875rem !important ;
  line-height: 1.5 !important;
}

.value {
  color: grey !important;
}

/**
MENUS
*/
.navbar {
 // max-height: 60px !important;
  // desactivado para añadir los temas
  //background: white !important;
}
/* @media screen and (max-width: 1024px) {
  .navbar {
    max-height: 50px !important;
  }
} */

// Espacio entre opciones de la barra de menú
.side-nav .collapsible a {
  height: 36px;
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/**
MIGAS DE PAN
*/
.breadcrumb{margin-top: -10px;}


/**
MODALES
*/
.modal-dialog .modal-content {
  border-radius: 15px !important;
}

.nested-modal {
  z-index: 1250;
}
.nested-modal-backdrop{
  opacity: .5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1240;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  transition: opacity 0.15s linear;
  box-sizing: border-box;
}

.dynamic-modal-scroll {
    overflow-y: scroll !important;

}


/**
FILTROS: ACORDEON
*/
.cabecerafiltros{
  background-color: #cde1f6 !important;
}
.cuerpofiltros{
  background-color: #f7fafe !important;
  width: 100%;
}
.acordeon-overflow-vis {
  overflow: visible !important;
}


/**
SCROLL
**/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(125, 171, 224);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(98, 134, 175);
}


/*****
 Listados
***********/
  .tabla_listado {
    min-height: 500px;
  }

  .filacabecera {
    border-top: none;

    padding-top: 1.1rem;
    padding-bottom: 1rem;

    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;

    min-width: 9rem;
    padding: 0.75rem;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: justify;
    border-collapse: collapse;
  }
  .datos {
    min-height: 100px;
    font-weight: 400;
    color: #212529;
    text-align: left;
    border-top: 1px solid #dee2e6;
  }
  .datos:hover {
    transition: 0.5s;
    background-color: rgba(0, 0, 0, 0.075);
    cursor: pointer;
  }
  .celda-responsive {
    padding-top: 1.1rem;
    padding-bottom: 1rem;
    padding: 0.75rem;
    //vertical-align: top;
   // border-top: 1px solid #dee2e6;
    font-size: 0.9rem;
    font-weight: 400;
    vertical-align: middle;
  }
  .labelcabecera {
    font-weight: bolder;
  }
  .labelresponsive {
    display: none;
  }

  .stepper{
    transition: height 0.5s ease !important;
  }

  @media screen and (max-width: 1280px) {
    .filacabecera {
      display: none;
    }
    .datos {
      min-height: 200px;
      border-bottom:2px solid #2d5373;
    }
    .labelresponsive {
      display: inline;
    }
  }

  @media screen and (max-width: 900px) {
    .hideresponsive {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    .celda-responsive {
      border-top: none;
    }
  }


  /**
  Prefijo Icono para select: Temporal hasta que lo arreglen en MDBootstrap
  */
  .md-form .prefix ~ mdb-select-2 {
    margin-left: 2rem;
    width: calc(100% - 2rem);
  }

  .md-form .prefix ~ mdb-select-2 .mdb-select-label {
    margin-left: 2rem;
  }

  .datepicker-inline { position: fixed !important; }

/**
Spinner de carga: Temporal hasta que lo arreglen en MDBootstrap
*/

  .preloader-wrapper {
    mdb-spinners {
      width: 100% !important;
      height: 100% !important;
      svg path {
        transition: stroke 0.3s;
        stroke-width: 6px !important;
        fill: transparent;
      }
      &[mode='indeterminate'] svg {
        animation-duration: 5332ms, 1333ms !important;
      }
    }
    mdb-spinners.spinner-blue-only svg path {
      stroke: #4285f4;
    }

    mdb-spinners.spinner-red-only svg path {
      stroke: #db4437;
    }

    mdb-spinners.spinner-yellow-only svg path {
      stroke: #f4b400;
    }

    mdb-spinners.spinner-green-only svg path {
      stroke: #0f9d58;
    }
  }


















/**
SKIN PGA
*/

.white-skin .side-nav .collapsible li a:not(.collapsible-header):hover,
.white-skin .side-nav .collapsible li a:not(.collapsible-header).active,
.white-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: white !important;
}


/* Margin top */
.mt-pga {
  margin-top: -1.5rem !important;
}

$skins: () !default;
$skins: map-merge((
  "PGA": (
    "skin-primary-color": #4682b4,
    "skin-navbar": #fff,
    "skin-footer-color": #fff,
    "skin-accent": #4682b4,
    "skin-flat": #fff,
    "skin-sidenav-item": #fff,
    "skin-sidenav-item-hover": #fff,
    "skin-gradient-start": #fff,
    "skin-gradient-end": #fff,
    "skin-mask-slight": #fff,
    "skin-mask-light": #fff,
    "skin-mask-strong": #fff,
    "skin-sn-child": #fff,
    "skin-btn-primary": #4682b4 ,
    "skin-btn-secondary": #d53032,
    "skin-btn-default": #52585c,
    "skin-text":#2d5373
  )
), $skins);


@import 'ng-uikit-pro-standard/assets/scss/core/mixins';
@import 'ng-uikit-pro-standard/assets/scss/core/colors';
@import 'ng-uikit-pro-standard/assets/scss/core/variables';
@import 'ng-uikit-pro-standard/assets/scss/core/variables-pro';
@import 'ng-uikit-pro-standard/assets/scss/core/msc/skins-pro';
@import "ng-uikit-pro-standard/assets/scss/addons-pro/_timelines-pro.scss";
@import "ng-uikit-pro-standard/assets/scss/addons-pro/_timeline-pro.scss";

@import "ng-uikit-pro-standard/assets/scss/sections-pro/_pricing-pro.scss";

@import "ng-uikit-pro-standard/assets/scss/addons-pro/_ecommerce-pro.scss";
