@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
/* BOTON TRANSPARENTE Y SIN BORDE */
.icon-button {
  background-color: inherit;
  border: 0;
}

.custom-md-tabs-header {
  margin-left: unset !important;
  margin-right: unset !important;
}

/**
  Tablas
**/
table.table th, table.table:not(.inline-buttons-table) td {
  padding: 0.4rem !important;
}

.table-cell-save {
  border: none !important;
}

/**TABLA CON BOTONES INLINE**/
.inline-buttons-table {
  border-collapse: collapse;
  width: 100%;
  border: 2px solid #9fa0ae;
  table-layout: fixed; /* Fijamos el ancho de la tabla */
}
.inline-buttons-table th {
  border: 1px solid #9fa0ae !important;
  background-color: #f2f2f2 !important;
  font-weight: bold !important;
  /*
  @media screen and (max-width: 1600px) {
    .excel-table th.buttons-column {
      width: 2%;
    }

    .excel-table th.numbers-column {
      width: 7%;
    }

    .excel-table th.code-column {
      width: 8%;
    }

    .excel-table th.medium-column {
      width: 18%;
    }
  }
  */
}
.inline-buttons-table th.buttons-column {
  width: 7% !important; /* Un ancho relativo a dos caracteres (5 caracteres x 2) */
  text-align: center !important;
  padding: 0.25rem 0.5rem !important;
}
.inline-buttons-table th.icon-column {
  width: 5%;
  text-align: center !important;
  padding: 0.25rem 0.5rem !important;
}
.inline-buttons-table th.icon-column-small {
  width: 2%;
  text-align: center !important;
  padding: 0.25rem 0.5rem !important;
}
.inline-buttons-table th.numbers-column {
  width: 5%;
}
.inline-buttons-table th.percent-column {
  width: 4%;
}
.inline-buttons-table th.code-column {
  width: 8%;
}
.inline-buttons-table th.medium-column {
  width: 15%;
}
.inline-buttons-table th.large-column {
  width: 20%;
}
@media screen and (max-width: 1800px) {
  .inline-buttons-table th.buttons-column {
    width: 8% !important;
  }
  .inline-buttons-table th.code-column {
    width: 10%;
  }
  .inline-buttons-table th.medium-column {
    width: 16%;
  }
  .inline-buttons-table th.numbers-column {
    width: 6%;
  }
}
@media screen and (max-width: 1300px) {
  .inline-buttons-table th.buttons-column {
    width: 12% !important;
  }
  .inline-buttons-table th.medium-column {
    width: 25%;
  }
  .inline-buttons-table th.icon-column {
    width: 7%;
  }
  .inline-buttons-table th.numbers-column {
    width: 8%;
  }
}
.inline-buttons-table td {
  border: 1px solid #9fa0ae;
  padding: 0 !important;
}
.inline-buttons-table td > mdb-checkbox {
  text-align: center;
}
.inline-buttons-table td > mdb-icon {
  vertical-align: bottom;
}
.inline-buttons-table td > div.icono-input {
  display: flex;
  align-items: center;
}
.inline-buttons-table td > div.icono-input input {
  margin-left: 5px;
  flex-grow: 1; /* Permite al input crecer para ocupar el espacio restante */
}
.inline-buttons-table td > div.icono-input select {
  flex-grow: 1;
}
.inline-buttons-table td > span {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  display: block;
  font-weight: 400;
}
.inline-buttons-table td.buttons-column {
  width: 7%; /* Un ancho relativo a dos caracteres (5 caracteres x 2) */
  text-align: center;
  padding: 0.25rem 0.5rem !important;
}
.inline-buttons-table td.buttons-column > .form-check-input[type=radio] + label {
  height: 1.2625rem;
  padding-left: 25px;
}
.inline-buttons-table td.icon-column {
  width: 5%;
  text-align: center !important;
  padding: 0.25rem 0.5rem !important;
}
.inline-buttons-table td.icon-column:has(mdb-icon):not(:has(a)) {
  padding: unset !important;
}
.inline-buttons-table td.icon-column-small {
  width: 2%;
  text-align: center !important;
  padding: 0.25rem 0.5rem !important;
}
.inline-buttons-table td mdb-error {
  top: unset;
  left: unset;
  position: relative;
}
.inline-buttons-table input[type=text],
.inline-buttons-table input[type=number] {
  border: none; /* Eliminamos el borde del input */
  outline: none; /* Eliminamos el contorno al enfocar el input */
  box-sizing: border-box; /* El ancho incluirá el padding y el borde */
  background-color: inherit;
}
.inline-buttons-table select {
  height: 100%;
  border: none; /* Eliminamos el borde del select */
  outline: none; /* Eliminamos el contorno al enfocar el select */
  box-sizing: border-box; /* El ancho incluirá el padding y el borde */
  background-color: inherit;
}
.inline-buttons-table select:focus {
  background-color: inherit;
}

/**PARA LOS FORMULARIOS DE ALTA**/
.divCustomGlobal {
  overflow-x: hidden;
}

.divCustomSection {
  margin: unset;
  padding: unset;
  background-color: #eeeeee;
  color: gray;
  font-weight: 500;
  padding: 10px 24px;
}

/**TABSET PERSONALIZADO***/
.custom-tabset .container-fluid {
  margin: unset;
  padding: unset;
}
.custom-tabset .container-fluid .row {
  margin: unset;
}
.custom-tabset .container-fluid .row .col-md-12 {
  margin: unset;
  padding: unset;
}
.custom-tabset .container-fluid .row .col-md-12 div.tab-content {
  margin: unset;
  box-shadow: none;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs.secondTab {
  background-color: #04927f !important;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs.secondTab li.nav-item {
  margin: unset;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs.secondTab li.nav-item a {
  padding: 10px 24px;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs.secondTab li.nav-item a.nav-link {
  border-bottom: 3px solid black;
  color: black;
  font-weight: 500;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs.secondTab li.nav-item a.nav-link.active {
  border-bottom: 4px solid white;
  color: white;
  font-weight: 500;
  /*border-color: gray;
  color: gray;
  font-weight: 500;*/
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs.secondTab li:not(.nav-item) {
  margin: unset;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs.secondTab li:not(.nav-item) a {
  padding: 10px 24px;
  border-color: black;
  color: black;
  font-weight: 500;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs {
  border-radius: unset;
  margin: unset;
  background-color: #EEEEEE !important;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs li.nav-item {
  margin: unset;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs li.nav-item a {
  padding: 10px 24px;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs li.nav-item a.nav-link {
  border-bottom: 3px solid #fff;
  color: gray;
  font-weight: 500;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs li.nav-item a.nav-link.active {
  border-bottom: 4px solid #2196F3;
  color: gray;
  font-weight: 500;
  /*border-color: gray;
  color: gray;
  font-weight: 500;*/
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs li:not(.nav-item) {
  margin: unset;
}
.custom-tabset .container-fluid .row .col-md-12 ul.classic-tabs li:not(.nav-item) a {
  padding: 10px 24px;
  border-color: #424242;
  color: #424242;
  font-weight: 500;
}

/**
PESTAÑAS APP
*/
mat-tab-group.app-tabs .mat-mdc-tab.mat-mdc-focus-indicator {
  background-color: rgba(158, 158, 158, 0.3019607843);
}

mat-tab-group.app-tabs .mat-mdc-tab.mat-mdc-focus-indicator > * > .mdc-tab__text-label {
  color: #666;
  font-weight: normal;
}

mat-tab-group.app-tabs .mat-mdc-tab.mat-mdc-focus-indicator:not(.mdc-tab-indicator--active):has(.edit-tab) {
  background-color: #ffecb3 !important;
}

mat-tab-group.app-tabs .mat-mdc-tab.mdc-tab-indicator--active {
  background-color: #2196f3 !important;
  opacity: 1 !important;
}
mat-tab-group.app-tabs .mat-mdc-tab.mdc-tab-indicator--active > * > .mdc-tab__text-label {
  color: #fff !important;
  font-weight: normal;
  transition: none;
}

.mdc-tab {
  letter-spacing: normal;
}

/***/
/* .has-register {
  background-color: #fee8bb !important;
} */
/**
  Formularios
**/
.md-form.md-outline {
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important;
}

.md-form {
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important;
}

/**
NOTIFICACION
*/
.opacidadWarning {
  opacity: 0.8 !important;
}

/**
STEPPER
**/
:host ::ng-deep .card-body ul.stepper.horizontal .step.done .step-title:before {
  font-family: unset !important;
}

.step-title:before {
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free" !important;
}

/**
BADGES
*/
.tab-badge {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1607843137), 0 2px 10px rgba(0, 0, 0, 0.1215686275);
  color: #fff !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badgeinfo {
  background-color: #00bcd4 !important;
}

/**
FORMULARIOS
*/
.form-control {
  color: grey !important;
}

.form-control:disabled, .form-control[readonly] {
  cursor: not-allowed !important;
}

mdb-select .single {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
}

.value {
  color: grey !important;
}

/**
MENUS
*/
/* @media screen and (max-width: 1024px) {
  .navbar {
    max-height: 50px !important;
  }
} */
.side-nav .collapsible a {
  height: 36px;
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/**
MIGAS DE PAN
*/
.breadcrumb {
  margin-top: -10px;
}

/**
MODALES
*/
.modal-dialog .modal-content {
  border-radius: 15px !important;
}

.nested-modal {
  z-index: 1250;
}

.nested-modal-backdrop {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1240;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  transition: opacity 0.15s linear;
  box-sizing: border-box;
}

.dynamic-modal-scroll {
  overflow-y: scroll !important;
}

/**
FILTROS: ACORDEON
*/
.cabecerafiltros {
  background-color: #cde1f6 !important;
}

.cuerpofiltros {
  background-color: #f7fafe !important;
  width: 100%;
}

.acordeon-overflow-vis {
  overflow: visible !important;
}

/**
SCROLL
**/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(125, 171, 224);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(98, 134, 175);
}

/*****
 Listados
***********/
.tabla_listado {
  min-height: 500px;
}

.filacabecera {
  border-top: none;
  padding-top: 1.1rem;
  padding-bottom: 1rem;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  min-width: 9rem;
  padding: 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: justify;
  border-collapse: collapse;
}

.datos {
  min-height: 100px;
  font-weight: 400;
  color: #212529;
  text-align: left;
  border-top: 1px solid #dee2e6;
}

.datos:hover {
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.075);
  cursor: pointer;
}

.celda-responsive {
  padding-top: 1.1rem;
  padding-bottom: 1rem;
  padding: 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  vertical-align: middle;
}

.labelcabecera {
  font-weight: bolder;
}

.labelresponsive {
  display: none;
}

.stepper {
  transition: height 0.5s ease !important;
}

@media screen and (max-width: 1280px) {
  .filacabecera {
    display: none;
  }
  .datos {
    min-height: 200px;
    border-bottom: 2px solid #2d5373;
  }
  .labelresponsive {
    display: inline;
  }
}
@media screen and (max-width: 900px) {
  .hideresponsive {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .celda-responsive {
    border-top: none;
  }
}
/**
Prefijo Icono para select: Temporal hasta que lo arreglen en MDBootstrap
*/
.md-form .prefix ~ mdb-select-2 {
  margin-left: 2rem;
  width: calc(100% - 2rem);
}

.md-form .prefix ~ mdb-select-2 .mdb-select-label {
  margin-left: 2rem;
}

.datepicker-inline {
  position: fixed !important;
}

/**
Spinner de carga: Temporal hasta que lo arreglen en MDBootstrap
*/
.preloader-wrapper mdb-spinners {
  width: 100% !important;
  height: 100% !important;
}
.preloader-wrapper mdb-spinners svg path {
  transition: stroke 0.3s;
  stroke-width: 6px !important;
  fill: transparent;
}
.preloader-wrapper mdb-spinners[mode=indeterminate] svg {
  animation-duration: 5332ms, 1333ms !important;
}
.preloader-wrapper mdb-spinners.spinner-blue-only svg path {
  stroke: #4285f4;
}
.preloader-wrapper mdb-spinners.spinner-red-only svg path {
  stroke: #db4437;
}
.preloader-wrapper mdb-spinners.spinner-yellow-only svg path {
  stroke: #f4b400;
}
.preloader-wrapper mdb-spinners.spinner-green-only svg path {
  stroke: #0f9d58;
}

/**
SKIN PGA
*/
.white-skin .side-nav .collapsible li a:not(.collapsible-header):hover,
.white-skin .side-nav .collapsible li a:not(.collapsible-header).active,
.white-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: white !important;
}

/* Margin top */
.mt-pga {
  margin-top: -1.5rem !important;
}

/*** Global ***/
.white-skin .gradient {
  background: #ababab;
  background: linear-gradient(135deg, #ababab 0%, #fff 100%);
}
.white-skin .primary-color {
  background-color: #4285f4 !important;
}
.white-skin .navbar {
  background-color: #fff;
  color: #424242;
}
.white-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.white-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .white-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .white-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #2a75f3;
}
.white-skin .navbar .navbar-nav .nav-item a {
  color: #424242;
}
.white-skin .navbar.double-nav a {
  color: #424242;
}
.white-skin .navbar form .md-form .form-control {
  color: #424242;
  font-weight: 300;
}
.white-skin .navbar form .md-form .form-control::placeholder {
  color: #424242;
}
.white-skin .navbar.navbar-dark form .md-form .form-control {
  color: #fff;
}
.white-skin .navbar.navbar-dark form .md-form .form-control::placeholder {
  color: #fff;
}
.white-skin .page-footer {
  background-color: #6f7178;
}
.white-skin .side-nav {
  background-color: #f9f9f9;
}
.white-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.white-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #0d5bdd;
}
.white-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.white-skin .side-nav .social a .fas,
.white-skin .side-nav .social a .fab,
.white-skin .side-nav .social a .far {
  color: #424242;
}
.white-skin .side-nav .social a:hover .fas,
.white-skin .side-nav .social a:hover .fab,
.white-skin .side-nav .social a:hover .far {
  color: #4285f4 !important;
  transition: all 0.3s linear;
}
.white-skin .side-nav .search-form .md-form input {
  color: #424242 !important;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.white-skin .side-nav .search-form .md-form input::placeholder {
  color: rgba(66, 66, 66, 0.5) !important;
}
.white-skin .side-nav .collapsible li {
  background-color: transparent;
}
.white-skin .side-nav .collapsible li a {
  font-weight: 400;
}
.white-skin .side-nav .collapsible li .collapsible-header {
  color: #424242;
  transition: all 0.3s linear;
}
.white-skin .side-nav .collapsible li .collapsible-header.active {
  color: #4285f4;
  background-color: transparent;
}
.white-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.white-skin .side-nav .collapsible li .collapsible-body a {
  color: #424242;
}
.white-skin .side-nav .collapsible li .collapsible-body a:hover, .white-skin .side-nav .collapsible li .collapsible-body a.active, .white-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #4285f4;
}
.white-skin .side-nav .collapsible li .collapsible-body a .fas,
.white-skin .side-nav .collapsible li .collapsible-body a .fab,
.white-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #424242;
}
.white-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #424242;
  transition: all 0.3s linear;
}
.white-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .white-skin .side-nav .collapsible li a:not(.collapsible-header).active, .white-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #4285f4 !important;
}
.white-skin .side-nav .sidenav-bg:after, .white-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(255, 255, 255, 0.8);
}
.white-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(255, 255, 255, 0.65);
}
.white-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(255, 255, 255, 0.5);
}
.white-skin .btn-primary {
  background-color: #4285f4 !important;
  color: #fff;
}
.white-skin .btn-primary:hover {
  background-color: #5a95f5;
  color: #fff;
}
.white-skin .btn-primary:focus, .white-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-primary:focus, .white-skin .btn-primary:active, .white-skin .btn-primary.active {
  background-color: #0b51c5;
}
.white-skin .btn-primary.dropdown-toggle {
  background-color: #4285f4 !important;
}
.white-skin .btn-primary.dropdown-toggle:hover, .white-skin .btn-primary.dropdown-toggle:focus {
  background-color: #5a95f5 !important;
}
.white-skin .btn-primary:not([disabled]):not(.disabled):active, .white-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .white-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0b51c5 !important;
}
.white-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .white-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .primary-ic {
  color: #4285f4 !important;
}
.white-skin .primary-ic:hover, .white-skin .primary-ic:focus {
  color: #4285f4;
}
.white-skin a.btn:not([href]):not([tabindex]),
.white-skin a.btn:not([href]):not([tabindex]):focus,
.white-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.white-skin table.table a.btn.btn-primary {
  color: #fff;
}
.white-skin .btn-secondary {
  background-color: #fc685f !important;
  color: #fff;
}
.white-skin .btn-secondary:hover {
  background-color: #fc8078;
  color: #fff;
}
.white-skin .btn-secondary:focus, .white-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-secondary:focus, .white-skin .btn-secondary:active, .white-skin .btn-secondary.active {
  background-color: #f01205;
}
.white-skin .btn-secondary.dropdown-toggle {
  background-color: #fc685f !important;
}
.white-skin .btn-secondary.dropdown-toggle:hover, .white-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #fc8078 !important;
}
.white-skin .btn-secondary:not([disabled]):not(.disabled):active, .white-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .white-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #f01205 !important;
}
.white-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .white-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .secondary-ic {
  color: #fc685f !important;
}
.white-skin .secondary-ic:hover, .white-skin .secondary-ic:focus {
  color: #fc685f;
}
.white-skin a.btn:not([href]):not([tabindex]),
.white-skin a.btn:not([href]):not([tabindex]):focus,
.white-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.white-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.white-skin .btn-default {
  background-color: #454545 !important;
  color: #fff;
}
.white-skin .btn-default:hover {
  background-color: #525252;
  color: #fff;
}
.white-skin .btn-default:focus, .white-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-default:focus, .white-skin .btn-default:active, .white-skin .btn-default.active {
  background-color: #121212;
}
.white-skin .btn-default.dropdown-toggle {
  background-color: #454545 !important;
}
.white-skin .btn-default.dropdown-toggle:hover, .white-skin .btn-default.dropdown-toggle:focus {
  background-color: #525252 !important;
}
.white-skin .btn-default:not([disabled]):not(.disabled):active, .white-skin .btn-default:not([disabled]):not(.disabled).active, .show > .white-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #121212 !important;
}
.white-skin .btn-default:not([disabled]):not(.disabled):active:focus, .white-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .default-ic {
  color: #454545 !important;
}
.white-skin .default-ic:hover, .white-skin .default-ic:focus {
  color: #454545;
}
.white-skin a.btn:not([href]):not([tabindex]),
.white-skin a.btn:not([href]):not([tabindex]):focus,
.white-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.white-skin table.table a.btn.btn-default {
  color: #fff;
}
.white-skin .btn-outline-primary {
  border: 2px solid #4285f4 !important;
  background-color: transparent !important;
  color: #4285f4 !important;
}
.white-skin .btn-outline-primary:hover, .white-skin .btn-outline-primary:focus, .white-skin .btn-outline-primary:active, .white-skin .btn-outline-primary:active:focus, .white-skin .btn-outline-primary.active {
  border-color: #4285f4 !important;
  background-color: transparent !important;
  color: #4285f4 !important;
}
.white-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .white-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .white-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #4285f4 !important;
}
.white-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .white-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-outline-secondary {
  border: 2px solid #fc685f !important;
  background-color: transparent !important;
  color: #fc685f !important;
}
.white-skin .btn-outline-secondary:hover, .white-skin .btn-outline-secondary:focus, .white-skin .btn-outline-secondary:active, .white-skin .btn-outline-secondary:active:focus, .white-skin .btn-outline-secondary.active {
  border-color: #fc685f !important;
  background-color: transparent !important;
  color: #fc685f !important;
}
.white-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .white-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .white-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #fc685f !important;
}
.white-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .white-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-outline-default {
  border: 2px solid #454545 !important;
  background-color: transparent !important;
  color: #454545 !important;
}
.white-skin .btn-outline-default:hover, .white-skin .btn-outline-default:focus, .white-skin .btn-outline-default:active, .white-skin .btn-outline-default:active:focus, .white-skin .btn-outline-default.active {
  border-color: #454545 !important;
  background-color: transparent !important;
  color: #454545 !important;
}
.white-skin .btn-outline-default:not([disabled]):not(.disabled):active, .white-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .white-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #454545 !important;
}
.white-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .white-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .card .btn-action {
  background: #454545;
}
.white-skin .card .btn-action:hover, .white-skin .card .btn-action:focus {
  background-color: #525252 !important;
}
.white-skin .card .btn-action.active {
  background-color: #121212 !important;
}
.white-skin .md-outline input[type=text]:focus:not([readonly]),
.white-skin .md-outline input[type=password]:focus:not([readonly]),
.white-skin .md-outline input[type=email]:focus:not([readonly]),
.white-skin .md-outline input[type=url]:focus:not([readonly]),
.white-skin .md-outline input[type=time]:focus:not([readonly]),
.white-skin .md-outline input[type=date]:focus:not([readonly]),
.white-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.white-skin .md-outline input[type=tel]:focus:not([readonly]),
.white-skin .md-outline input[type=number]:focus:not([readonly]),
.white-skin .md-outline input[type=search-md]:focus:not([readonly]),
.white-skin .md-outline input[type=search]:focus:not([readonly]),
.white-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #4285f4;
  box-shadow: inset 0px 0px 0px 1px #4285f4;
}
.white-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.white-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #4285f4;
}
.white-skin .md-bg input[type=text],
.white-skin .md-bg input[type=password],
.white-skin .md-bg input[type=email],
.white-skin .md-bg input[type=url],
.white-skin .md-bg input[type=time],
.white-skin .md-bg input[type=date],
.white-skin .md-bg input[type=datetime-local],
.white-skin .md-bg input[type=tel],
.white-skin .md-bg input[type=number],
.white-skin .md-bg input[type=search-md],
.white-skin .md-bg input[type=search],
.white-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #4285f4, #4285f4), linear-gradient(to bottom, #ced4da, #ced4da);
}
.white-skin input[type=email]:focus:not([readonly]),
.white-skin input[type=text]:focus:not([readonly]),
.white-skin input[type=password]:focus:not([readonly]),
.white-skin input[type=number]:focus:not([readonly]),
.white-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #4285f4;
  box-shadow: 0 1px 0 0 #4285f4;
}
.white-skin input[type=email]:focus:not([readonly]) + label,
.white-skin input[type=text]:focus:not([readonly]) + label,
.white-skin input[type=password]:focus:not([readonly]) + label,
.white-skin input[type=number]:focus:not([readonly]) + label,
.white-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #4285f4;
}
.white-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #4285f4;
  border-bottom: 2px solid #4285f4;
}
.white-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.white-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #4285f4;
  border-color: #4285f4;
}
.white-skin .md-form .prefix.active {
  color: #4285f4;
}
.white-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #4285f4 !important;
}
.white-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #4285f4;
  box-shadow: inset 0px 0px 0px 1px #4285f4;
}
.white-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #4285f4;
}
.white-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.white-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.white-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #4285f4 !important;
}
.white-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.white-skin .top-nav-collapse {
  background-color: #fff;
}
.white-skin .carousel-multi-item .controls-top > a,
.white-skin .carousel-multi-item .carousel-indicators li,
.white-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #4285f4;
}
.white-skin .form-header,
.white-skin .card-header {
  background-color: #4c8bf5;
}
.white-skin .spinner-primary-color,
.white-skin .spinner-primary-color-only {
  border-color: #4285f4;
}
.white-skin .pagination-primary-color .page-item.active .page-link,
.white-skin .pagination-primary-color .page-item.active .page-link:focus,
.white-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #4285f4;
}
.white-skin .pagination-primary-color .page-link {
  color: #4285f4;
}
.white-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.black-skin .gradient {
  background: #000;
  background: linear-gradient(135deg, #000 0%, #616161 100%);
}
.black-skin .primary-color {
  background-color: #2ad1a3 !important;
}
.black-skin .navbar {
  background-color: #222;
  color: #fff;
}
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #26bc92;
}
.black-skin .navbar.double-nav a {
  color: #fff;
}
.black-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.black-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.black-skin .page-footer {
  background-color: #222;
}
.black-skin .side-nav {
  background-color: #222;
}
.black-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.black-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #1d9171;
}
.black-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.black-skin .side-nav .social a:hover .fas,
.black-skin .side-nav .social a:hover .fab,
.black-skin .side-nav .social a:hover .far {
  color: #2ad1a3 !important;
  transition: all 0.3s linear;
}
.black-skin .side-nav .collapsible li {
  background-color: transparent;
}
.black-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.black-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.black-skin .side-nav .collapsible li .collapsible-body a:hover, .black-skin .side-nav .collapsible li .collapsible-body a.active, .black-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #2ad1a3;
}
.black-skin .side-nav .collapsible li .collapsible-body a .fas,
.black-skin .side-nav .collapsible li .collapsible-body a .fab,
.black-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.black-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.black-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .black-skin .side-nav .collapsible li a:not(.collapsible-header).active, .black-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #2ad1a3 !important;
}
.black-skin .side-nav .sidenav-bg:after, .black-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(0, 0, 0, 0.8);
}
.black-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(0, 0, 0, 0.65);
}
.black-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(0, 0, 0, 0.5);
}
.black-skin .btn-primary {
  background-color: #2ad1a3 !important;
  color: #fff;
}
.black-skin .btn-primary:hover {
  background-color: #3dd8ad;
  color: #fff;
}
.black-skin .btn-primary:focus, .black-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-primary:focus, .black-skin .btn-primary:active, .black-skin .btn-primary.active {
  background-color: #197c61;
}
.black-skin .btn-primary.dropdown-toggle {
  background-color: #2ad1a3 !important;
}
.black-skin .btn-primary.dropdown-toggle:hover, .black-skin .btn-primary.dropdown-toggle:focus {
  background-color: #3dd8ad !important;
}
.black-skin .btn-primary:not([disabled]):not(.disabled):active, .black-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .black-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #197c61 !important;
}
.black-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .black-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .primary-ic {
  color: #2ad1a3 !important;
}
.black-skin .primary-ic:hover, .black-skin .primary-ic:focus {
  color: #2ad1a3;
}
.black-skin a.btn:not([href]):not([tabindex]),
.black-skin a.btn:not([href]):not([tabindex]):focus,
.black-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.black-skin table.table a.btn.btn-primary {
  color: #fff;
}
.black-skin .btn-secondary {
  background-color: #0f478a !important;
  color: #fff;
}
.black-skin .btn-secondary:hover {
  background-color: #1253a1;
  color: #fff;
}
.black-skin .btn-secondary:focus, .black-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-secondary:focus, .black-skin .btn-secondary:active, .black-skin .btn-secondary.active {
  background-color: #05182e;
}
.black-skin .btn-secondary.dropdown-toggle {
  background-color: #0f478a !important;
}
.black-skin .btn-secondary.dropdown-toggle:hover, .black-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #1253a1 !important;
}
.black-skin .btn-secondary:not([disabled]):not(.disabled):active, .black-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .black-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #05182e !important;
}
.black-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .black-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .secondary-ic {
  color: #0f478a !important;
}
.black-skin .secondary-ic:hover, .black-skin .secondary-ic:focus {
  color: #0f478a;
}
.black-skin a.btn:not([href]):not([tabindex]),
.black-skin a.btn:not([href]):not([tabindex]):focus,
.black-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.black-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.black-skin .btn-default {
  background-color: #222222 !important;
  color: #fff;
}
.black-skin .btn-default:hover {
  background-color: #2f2f2f;
  color: #fff;
}
.black-skin .btn-default:focus, .black-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-default:focus, .black-skin .btn-default:active, .black-skin .btn-default.active {
  background-color: black;
}
.black-skin .btn-default.dropdown-toggle {
  background-color: #222222 !important;
}
.black-skin .btn-default.dropdown-toggle:hover, .black-skin .btn-default.dropdown-toggle:focus {
  background-color: #2f2f2f !important;
}
.black-skin .btn-default:not([disabled]):not(.disabled):active, .black-skin .btn-default:not([disabled]):not(.disabled).active, .show > .black-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: black !important;
}
.black-skin .btn-default:not([disabled]):not(.disabled):active:focus, .black-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .default-ic {
  color: #222222 !important;
}
.black-skin .default-ic:hover, .black-skin .default-ic:focus {
  color: #222222;
}
.black-skin a.btn:not([href]):not([tabindex]),
.black-skin a.btn:not([href]):not([tabindex]):focus,
.black-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.black-skin table.table a.btn.btn-default {
  color: #fff;
}
.black-skin .btn-outline-primary {
  border: 2px solid #2ad1a3 !important;
  background-color: transparent !important;
  color: #2ad1a3 !important;
}
.black-skin .btn-outline-primary:hover, .black-skin .btn-outline-primary:focus, .black-skin .btn-outline-primary:active, .black-skin .btn-outline-primary:active:focus, .black-skin .btn-outline-primary.active {
  border-color: #2ad1a3 !important;
  background-color: transparent !important;
  color: #2ad1a3 !important;
}
.black-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .black-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .black-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #2ad1a3 !important;
}
.black-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .black-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-outline-secondary {
  border: 2px solid #0f478a !important;
  background-color: transparent !important;
  color: #0f478a !important;
}
.black-skin .btn-outline-secondary:hover, .black-skin .btn-outline-secondary:focus, .black-skin .btn-outline-secondary:active, .black-skin .btn-outline-secondary:active:focus, .black-skin .btn-outline-secondary.active {
  border-color: #0f478a !important;
  background-color: transparent !important;
  color: #0f478a !important;
}
.black-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .black-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .black-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #0f478a !important;
}
.black-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .black-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-outline-default {
  border: 2px solid #222222 !important;
  background-color: transparent !important;
  color: #222222 !important;
}
.black-skin .btn-outline-default:hover, .black-skin .btn-outline-default:focus, .black-skin .btn-outline-default:active, .black-skin .btn-outline-default:active:focus, .black-skin .btn-outline-default.active {
  border-color: #222222 !important;
  background-color: transparent !important;
  color: #222222 !important;
}
.black-skin .btn-outline-default:not([disabled]):not(.disabled):active, .black-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .black-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #222222 !important;
}
.black-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .black-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .card .btn-action {
  background: #222222;
}
.black-skin .card .btn-action:hover, .black-skin .card .btn-action:focus {
  background-color: #2f2f2f !important;
}
.black-skin .card .btn-action.active {
  background-color: black !important;
}
.black-skin .md-outline input[type=text]:focus:not([readonly]),
.black-skin .md-outline input[type=password]:focus:not([readonly]),
.black-skin .md-outline input[type=email]:focus:not([readonly]),
.black-skin .md-outline input[type=url]:focus:not([readonly]),
.black-skin .md-outline input[type=time]:focus:not([readonly]),
.black-skin .md-outline input[type=date]:focus:not([readonly]),
.black-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.black-skin .md-outline input[type=tel]:focus:not([readonly]),
.black-skin .md-outline input[type=number]:focus:not([readonly]),
.black-skin .md-outline input[type=search-md]:focus:not([readonly]),
.black-skin .md-outline input[type=search]:focus:not([readonly]),
.black-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #2ad1a3;
  box-shadow: inset 0px 0px 0px 1px #2ad1a3;
}
.black-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.black-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #2ad1a3;
}
.black-skin .md-bg input[type=text],
.black-skin .md-bg input[type=password],
.black-skin .md-bg input[type=email],
.black-skin .md-bg input[type=url],
.black-skin .md-bg input[type=time],
.black-skin .md-bg input[type=date],
.black-skin .md-bg input[type=datetime-local],
.black-skin .md-bg input[type=tel],
.black-skin .md-bg input[type=number],
.black-skin .md-bg input[type=search-md],
.black-skin .md-bg input[type=search],
.black-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #2ad1a3, #2ad1a3), linear-gradient(to bottom, #ced4da, #ced4da);
}
.black-skin input[type=email]:focus:not([readonly]),
.black-skin input[type=text]:focus:not([readonly]),
.black-skin input[type=password]:focus:not([readonly]),
.black-skin input[type=number]:focus:not([readonly]),
.black-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #2ad1a3;
  box-shadow: 0 1px 0 0 #2ad1a3;
}
.black-skin input[type=email]:focus:not([readonly]) + label,
.black-skin input[type=text]:focus:not([readonly]) + label,
.black-skin input[type=password]:focus:not([readonly]) + label,
.black-skin input[type=number]:focus:not([readonly]) + label,
.black-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #2ad1a3;
}
.black-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #2ad1a3;
  border-bottom: 2px solid #2ad1a3;
}
.black-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.black-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #2ad1a3;
  border-color: #2ad1a3;
}
.black-skin .md-form .prefix.active {
  color: #2ad1a3;
}
.black-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #2ad1a3 !important;
}
.black-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #2ad1a3;
  box-shadow: inset 0px 0px 0px 1px #2ad1a3;
}
.black-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #2ad1a3;
}
.black-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.black-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.black-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #2ad1a3 !important;
}
.black-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.black-skin .top-nav-collapse {
  background-color: #222;
}
.black-skin .carousel-multi-item .controls-top > a,
.black-skin .carousel-multi-item .carousel-indicators li,
.black-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #2ad1a3;
}
.black-skin .form-header,
.black-skin .card-header {
  background-color: #30d5a8;
}
.black-skin .spinner-primary-color,
.black-skin .spinner-primary-color-only {
  border-color: #2ad1a3;
}
.black-skin .pagination-primary-color .page-item.active .page-link,
.black-skin .pagination-primary-color .page-item.active .page-link:focus,
.black-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #2ad1a3;
}
.black-skin .pagination-primary-color .page-link {
  color: #2ad1a3;
}
.black-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.cyan-skin .gradient {
  background: #114861;
  background: linear-gradient(135deg, #114861 0%, #45b0c0 100%);
}
.cyan-skin .primary-color {
  background-color: #4fada7 !important;
}
.cyan-skin .navbar {
  background-color: #114861;
  color: #fff;
}
.cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #eea82f;
}
.cyan-skin .navbar.double-nav a {
  color: #fff;
}
.cyan-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.cyan-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.cyan-skin .page-footer {
  background-color: #114861;
}
.cyan-skin .side-nav {
  background-color: #24879d;
}
.cyan-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.cyan-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #d99012;
}
.cyan-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.cyan-skin .side-nav .social a:hover .fas,
.cyan-skin .side-nav .social a:hover .fab,
.cyan-skin .side-nav .social a:hover .far {
  color: #f0b247 !important;
  transition: all 0.3s linear;
}
.cyan-skin .side-nav .collapsible li {
  background-color: transparent;
}
.cyan-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.cyan-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.cyan-skin .side-nav .collapsible li .collapsible-body a:hover, .cyan-skin .side-nav .collapsible li .collapsible-body a.active, .cyan-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #ffa742;
}
.cyan-skin .side-nav .collapsible li .collapsible-body a .fas,
.cyan-skin .side-nav .collapsible li .collapsible-body a .fab,
.cyan-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.cyan-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.cyan-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .cyan-skin .side-nav .collapsible li a:not(.collapsible-header).active, .cyan-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #ffa742 !important;
}
.cyan-skin .side-nav .sidenav-bg:after, .cyan-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(21, 78, 96, 0.8);
}
.cyan-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(21, 78, 96, 0.65);
}
.cyan-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(21, 78, 96, 0.5);
}
.cyan-skin .btn-primary {
  background-color: #ffa742 !important;
  color: #fff;
}
.cyan-skin .btn-primary:hover {
  background-color: #ffb35c;
  color: #fff;
}
.cyan-skin .btn-primary:focus, .cyan-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-primary:focus, .cyan-skin .btn-primary:active, .cyan-skin .btn-primary.active {
  background-color: #db7500;
}
.cyan-skin .btn-primary.dropdown-toggle {
  background-color: #ffa742 !important;
}
.cyan-skin .btn-primary.dropdown-toggle:hover, .cyan-skin .btn-primary.dropdown-toggle:focus {
  background-color: #ffb35c !important;
}
.cyan-skin .btn-primary:not([disabled]):not(.disabled):active, .cyan-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #db7500 !important;
}
.cyan-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .primary-ic {
  color: #ffa742 !important;
}
.cyan-skin .primary-ic:hover, .cyan-skin .primary-ic:focus {
  color: #ffa742;
}
.cyan-skin a.btn:not([href]):not([tabindex]),
.cyan-skin a.btn:not([href]):not([tabindex]):focus,
.cyan-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.cyan-skin table.table a.btn.btn-primary {
  color: #fff;
}
.cyan-skin .btn-secondary {
  background-color: #45b0c0 !important;
  color: #fff;
}
.cyan-skin .btn-secondary:hover {
  background-color: #58b8c6;
  color: #fff;
}
.cyan-skin .btn-secondary:focus, .cyan-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-secondary:focus, .cyan-skin .btn-secondary:active, .cyan-skin .btn-secondary.active {
  background-color: #286d77;
}
.cyan-skin .btn-secondary.dropdown-toggle {
  background-color: #45b0c0 !important;
}
.cyan-skin .btn-secondary.dropdown-toggle:hover, .cyan-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #58b8c6 !important;
}
.cyan-skin .btn-secondary:not([disabled]):not(.disabled):active, .cyan-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #286d77 !important;
}
.cyan-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .secondary-ic {
  color: #45b0c0 !important;
}
.cyan-skin .secondary-ic:hover, .cyan-skin .secondary-ic:focus {
  color: #45b0c0;
}
.cyan-skin a.btn:not([href]):not([tabindex]),
.cyan-skin a.btn:not([href]):not([tabindex]):focus,
.cyan-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.cyan-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.cyan-skin .btn-default {
  background-color: #114861 !important;
  color: #fff;
}
.cyan-skin .btn-default:hover {
  background-color: #155877;
  color: #fff;
}
.cyan-skin .btn-default:focus, .cyan-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-default:focus, .cyan-skin .btn-default:active, .cyan-skin .btn-default.active {
  background-color: #02080a;
}
.cyan-skin .btn-default.dropdown-toggle {
  background-color: #114861 !important;
}
.cyan-skin .btn-default.dropdown-toggle:hover, .cyan-skin .btn-default.dropdown-toggle:focus {
  background-color: #155877 !important;
}
.cyan-skin .btn-default:not([disabled]):not(.disabled):active, .cyan-skin .btn-default:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #02080a !important;
}
.cyan-skin .btn-default:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .default-ic {
  color: #114861 !important;
}
.cyan-skin .default-ic:hover, .cyan-skin .default-ic:focus {
  color: #114861;
}
.cyan-skin a.btn:not([href]):not([tabindex]),
.cyan-skin a.btn:not([href]):not([tabindex]):focus,
.cyan-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.cyan-skin table.table a.btn.btn-default {
  color: #fff;
}
.cyan-skin .btn-outline-primary {
  border: 2px solid #ffa742 !important;
  background-color: transparent !important;
  color: #ffa742 !important;
}
.cyan-skin .btn-outline-primary:hover, .cyan-skin .btn-outline-primary:focus, .cyan-skin .btn-outline-primary:active, .cyan-skin .btn-outline-primary:active:focus, .cyan-skin .btn-outline-primary.active {
  border-color: #ffa742 !important;
  background-color: transparent !important;
  color: #ffa742 !important;
}
.cyan-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .cyan-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ffa742 !important;
}
.cyan-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-outline-secondary {
  border: 2px solid #45b0c0 !important;
  background-color: transparent !important;
  color: #45b0c0 !important;
}
.cyan-skin .btn-outline-secondary:hover, .cyan-skin .btn-outline-secondary:focus, .cyan-skin .btn-outline-secondary:active, .cyan-skin .btn-outline-secondary:active:focus, .cyan-skin .btn-outline-secondary.active {
  border-color: #45b0c0 !important;
  background-color: transparent !important;
  color: #45b0c0 !important;
}
.cyan-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .cyan-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #45b0c0 !important;
}
.cyan-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-outline-default {
  border: 2px solid #114861 !important;
  background-color: transparent !important;
  color: #114861 !important;
}
.cyan-skin .btn-outline-default:hover, .cyan-skin .btn-outline-default:focus, .cyan-skin .btn-outline-default:active, .cyan-skin .btn-outline-default:active:focus, .cyan-skin .btn-outline-default.active {
  border-color: #114861 !important;
  background-color: transparent !important;
  color: #114861 !important;
}
.cyan-skin .btn-outline-default:not([disabled]):not(.disabled):active, .cyan-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #114861 !important;
}
.cyan-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .card .btn-action {
  background: #114861;
}
.cyan-skin .card .btn-action:hover, .cyan-skin .card .btn-action:focus {
  background-color: #155877 !important;
}
.cyan-skin .card .btn-action.active {
  background-color: #02080a !important;
}
.cyan-skin .md-outline input[type=text]:focus:not([readonly]),
.cyan-skin .md-outline input[type=password]:focus:not([readonly]),
.cyan-skin .md-outline input[type=email]:focus:not([readonly]),
.cyan-skin .md-outline input[type=url]:focus:not([readonly]),
.cyan-skin .md-outline input[type=time]:focus:not([readonly]),
.cyan-skin .md-outline input[type=date]:focus:not([readonly]),
.cyan-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.cyan-skin .md-outline input[type=tel]:focus:not([readonly]),
.cyan-skin .md-outline input[type=number]:focus:not([readonly]),
.cyan-skin .md-outline input[type=search-md]:focus:not([readonly]),
.cyan-skin .md-outline input[type=search]:focus:not([readonly]),
.cyan-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #f0b247;
  box-shadow: inset 0px 0px 0px 1px #f0b247;
}
.cyan-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.cyan-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #f0b247;
}
.cyan-skin .md-bg input[type=text],
.cyan-skin .md-bg input[type=password],
.cyan-skin .md-bg input[type=email],
.cyan-skin .md-bg input[type=url],
.cyan-skin .md-bg input[type=time],
.cyan-skin .md-bg input[type=date],
.cyan-skin .md-bg input[type=datetime-local],
.cyan-skin .md-bg input[type=tel],
.cyan-skin .md-bg input[type=number],
.cyan-skin .md-bg input[type=search-md],
.cyan-skin .md-bg input[type=search],
.cyan-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #f0b247, #f0b247), linear-gradient(to bottom, #ced4da, #ced4da);
}
.cyan-skin input[type=email]:focus:not([readonly]),
.cyan-skin input[type=text]:focus:not([readonly]),
.cyan-skin input[type=password]:focus:not([readonly]),
.cyan-skin input[type=number]:focus:not([readonly]),
.cyan-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #f0b247;
  box-shadow: 0 1px 0 0 #f0b247;
}
.cyan-skin input[type=email]:focus:not([readonly]) + label,
.cyan-skin input[type=text]:focus:not([readonly]) + label,
.cyan-skin input[type=password]:focus:not([readonly]) + label,
.cyan-skin input[type=number]:focus:not([readonly]) + label,
.cyan-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #f0b247;
}
.cyan-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #f0b247;
  border-bottom: 2px solid #f0b247;
}
.cyan-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.cyan-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #f0b247;
  border-color: #f0b247;
}
.cyan-skin .md-form .prefix.active {
  color: #f0b247;
}
.cyan-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #f0b247 !important;
}
.cyan-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #f0b247;
  box-shadow: inset 0px 0px 0px 1px #f0b247;
}
.cyan-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #f0b247;
}
.cyan-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.cyan-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.cyan-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #f0b247 !important;
}
.cyan-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.cyan-skin .top-nav-collapse {
  background-color: #114861;
}
.cyan-skin .carousel-multi-item .controls-top > a,
.cyan-skin .carousel-multi-item .carousel-indicators li,
.cyan-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #f0b247;
}
.cyan-skin .form-header,
.cyan-skin .card-header {
  background-color: #f1b650;
}
.cyan-skin .spinner-primary-color,
.cyan-skin .spinner-primary-color-only {
  border-color: #4fada7;
}
.cyan-skin .pagination-primary-color .page-item.active .page-link,
.cyan-skin .pagination-primary-color .page-item.active .page-link:focus,
.cyan-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #4fada7;
}
.cyan-skin .pagination-primary-color .page-link {
  color: #4fada7;
}
.cyan-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.mdb-skin .gradient {
  background: #0e2135;
  background: linear-gradient(135deg, #0e2135 0%, #2c76b8 100%);
}
.mdb-skin .primary-color {
  background-color: #33b5e5 !important;
}
.mdb-skin .navbar {
  background-color: #243a51;
  color: #fff;
}
.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #1dade2;
}
.mdb-skin .navbar.double-nav a {
  color: #fff;
}
.mdb-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.mdb-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.mdb-skin .page-footer {
  background-color: #243a51;
}
.mdb-skin .side-nav {
  background-color: #224562;
}
.mdb-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.mdb-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #178ab4;
}
.mdb-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.mdb-skin .side-nav .social a:hover .fas,
.mdb-skin .side-nav .social a:hover .fab,
.mdb-skin .side-nav .social a:hover .far {
  color: #33b5e5 !important;
  transition: all 0.3s linear;
}
.mdb-skin .side-nav .collapsible li {
  background-color: transparent;
}
.mdb-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.mdb-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.mdb-skin .side-nav .collapsible li .collapsible-body a:hover, .mdb-skin .side-nav .collapsible li .collapsible-body a.active, .mdb-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #67d5ff;
}
.mdb-skin .side-nav .collapsible li .collapsible-body a .fas,
.mdb-skin .side-nav .collapsible li .collapsible-body a .fab,
.mdb-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.mdb-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.mdb-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .mdb-skin .side-nav .collapsible li a:not(.collapsible-header).active, .mdb-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #67d5ff !important;
}
.mdb-skin .side-nav .sidenav-bg:after, .mdb-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(13, 36, 60, 0.8);
}
.mdb-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(13, 36, 60, 0.65);
}
.mdb-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(13, 36, 60, 0.5);
}
.mdb-skin .btn-primary {
  background-color: #33b5e5 !important;
  color: #fff;
}
.mdb-skin .btn-primary:hover {
  background-color: #4abde8;
  color: #fff;
}
.mdb-skin .btn-primary:focus, .mdb-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-primary:focus, .mdb-skin .btn-primary:active, .mdb-skin .btn-primary.active {
  background-color: #14799e;
}
.mdb-skin .btn-primary.dropdown-toggle {
  background-color: #33b5e5 !important;
}
.mdb-skin .btn-primary.dropdown-toggle:hover, .mdb-skin .btn-primary.dropdown-toggle:focus {
  background-color: #4abde8 !important;
}
.mdb-skin .btn-primary:not([disabled]):not(.disabled):active, .mdb-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #14799e !important;
}
.mdb-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .primary-ic {
  color: #33b5e5 !important;
}
.mdb-skin .primary-ic:hover, .mdb-skin .primary-ic:focus {
  color: #33b5e5;
}
.mdb-skin a.btn:not([href]):not([tabindex]),
.mdb-skin a.btn:not([href]):not([tabindex]):focus,
.mdb-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.mdb-skin table.table a.btn.btn-primary {
  color: #fff;
}
.mdb-skin .btn-secondary {
  background-color: #0e3d67 !important;
  color: #fff;
}
.mdb-skin .btn-secondary:hover {
  background-color: #114a7d;
  color: #fff;
}
.mdb-skin .btn-secondary:focus, .mdb-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-secondary:focus, .mdb-skin .btn-secondary:active, .mdb-skin .btn-secondary.active {
  background-color: #02080d;
}
.mdb-skin .btn-secondary.dropdown-toggle {
  background-color: #0e3d67 !important;
}
.mdb-skin .btn-secondary.dropdown-toggle:hover, .mdb-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #114a7d !important;
}
.mdb-skin .btn-secondary:not([disabled]):not(.disabled):active, .mdb-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #02080d !important;
}
.mdb-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .secondary-ic {
  color: #0e3d67 !important;
}
.mdb-skin .secondary-ic:hover, .mdb-skin .secondary-ic:focus {
  color: #0e3d67;
}
.mdb-skin a.btn:not([href]):not([tabindex]),
.mdb-skin a.btn:not([href]):not([tabindex]):focus,
.mdb-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.mdb-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.mdb-skin .btn-default {
  background-color: #1a6398 !important;
  color: #fff;
}
.mdb-skin .btn-default:hover {
  background-color: #1e71ae;
  color: #fff;
}
.mdb-skin .btn-default:focus, .mdb-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-default:focus, .mdb-skin .btn-default:active, .mdb-skin .btn-default.active {
  background-color: #0b2a41;
}
.mdb-skin .btn-default.dropdown-toggle {
  background-color: #1a6398 !important;
}
.mdb-skin .btn-default.dropdown-toggle:hover, .mdb-skin .btn-default.dropdown-toggle:focus {
  background-color: #1e71ae !important;
}
.mdb-skin .btn-default:not([disabled]):not(.disabled):active, .mdb-skin .btn-default:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0b2a41 !important;
}
.mdb-skin .btn-default:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .default-ic {
  color: #1a6398 !important;
}
.mdb-skin .default-ic:hover, .mdb-skin .default-ic:focus {
  color: #1a6398;
}
.mdb-skin a.btn:not([href]):not([tabindex]),
.mdb-skin a.btn:not([href]):not([tabindex]):focus,
.mdb-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.mdb-skin table.table a.btn.btn-default {
  color: #fff;
}
.mdb-skin .btn-outline-primary {
  border: 2px solid #33b5e5 !important;
  background-color: transparent !important;
  color: #33b5e5 !important;
}
.mdb-skin .btn-outline-primary:hover, .mdb-skin .btn-outline-primary:focus, .mdb-skin .btn-outline-primary:active, .mdb-skin .btn-outline-primary:active:focus, .mdb-skin .btn-outline-primary.active {
  border-color: #33b5e5 !important;
  background-color: transparent !important;
  color: #33b5e5 !important;
}
.mdb-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .mdb-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #33b5e5 !important;
}
.mdb-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-outline-secondary {
  border: 2px solid #0e3d67 !important;
  background-color: transparent !important;
  color: #0e3d67 !important;
}
.mdb-skin .btn-outline-secondary:hover, .mdb-skin .btn-outline-secondary:focus, .mdb-skin .btn-outline-secondary:active, .mdb-skin .btn-outline-secondary:active:focus, .mdb-skin .btn-outline-secondary.active {
  border-color: #0e3d67 !important;
  background-color: transparent !important;
  color: #0e3d67 !important;
}
.mdb-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .mdb-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #0e3d67 !important;
}
.mdb-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-outline-default {
  border: 2px solid #1a6398 !important;
  background-color: transparent !important;
  color: #1a6398 !important;
}
.mdb-skin .btn-outline-default:hover, .mdb-skin .btn-outline-default:focus, .mdb-skin .btn-outline-default:active, .mdb-skin .btn-outline-default:active:focus, .mdb-skin .btn-outline-default.active {
  border-color: #1a6398 !important;
  background-color: transparent !important;
  color: #1a6398 !important;
}
.mdb-skin .btn-outline-default:not([disabled]):not(.disabled):active, .mdb-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #1a6398 !important;
}
.mdb-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .card .btn-action {
  background: #1a6398;
}
.mdb-skin .card .btn-action:hover, .mdb-skin .card .btn-action:focus {
  background-color: #1e71ae !important;
}
.mdb-skin .card .btn-action.active {
  background-color: #0b2a41 !important;
}
.mdb-skin .md-outline input[type=text]:focus:not([readonly]),
.mdb-skin .md-outline input[type=password]:focus:not([readonly]),
.mdb-skin .md-outline input[type=email]:focus:not([readonly]),
.mdb-skin .md-outline input[type=url]:focus:not([readonly]),
.mdb-skin .md-outline input[type=time]:focus:not([readonly]),
.mdb-skin .md-outline input[type=date]:focus:not([readonly]),
.mdb-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.mdb-skin .md-outline input[type=tel]:focus:not([readonly]),
.mdb-skin .md-outline input[type=number]:focus:not([readonly]),
.mdb-skin .md-outline input[type=search-md]:focus:not([readonly]),
.mdb-skin .md-outline input[type=search]:focus:not([readonly]),
.mdb-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #33b5e5;
  box-shadow: inset 0px 0px 0px 1px #33b5e5;
}
.mdb-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.mdb-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #33b5e5;
}
.mdb-skin .md-bg input[type=text],
.mdb-skin .md-bg input[type=password],
.mdb-skin .md-bg input[type=email],
.mdb-skin .md-bg input[type=url],
.mdb-skin .md-bg input[type=time],
.mdb-skin .md-bg input[type=date],
.mdb-skin .md-bg input[type=datetime-local],
.mdb-skin .md-bg input[type=tel],
.mdb-skin .md-bg input[type=number],
.mdb-skin .md-bg input[type=search-md],
.mdb-skin .md-bg input[type=search],
.mdb-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #33b5e5, #33b5e5), linear-gradient(to bottom, #ced4da, #ced4da);
}
.mdb-skin input[type=email]:focus:not([readonly]),
.mdb-skin input[type=text]:focus:not([readonly]),
.mdb-skin input[type=password]:focus:not([readonly]),
.mdb-skin input[type=number]:focus:not([readonly]),
.mdb-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #33b5e5;
  box-shadow: 0 1px 0 0 #33b5e5;
}
.mdb-skin input[type=email]:focus:not([readonly]) + label,
.mdb-skin input[type=text]:focus:not([readonly]) + label,
.mdb-skin input[type=password]:focus:not([readonly]) + label,
.mdb-skin input[type=number]:focus:not([readonly]) + label,
.mdb-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #33b5e5;
}
.mdb-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #33b5e5;
  border-bottom: 2px solid #33b5e5;
}
.mdb-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.mdb-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #33b5e5;
  border-color: #33b5e5;
}
.mdb-skin .md-form .prefix.active {
  color: #33b5e5;
}
.mdb-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #33b5e5 !important;
}
.mdb-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #33b5e5;
  box-shadow: inset 0px 0px 0px 1px #33b5e5;
}
.mdb-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #33b5e5;
}
.mdb-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.mdb-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.mdb-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #33b5e5 !important;
}
.mdb-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.mdb-skin .top-nav-collapse {
  background-color: #243a51;
}
.mdb-skin .carousel-multi-item .controls-top > a,
.mdb-skin .carousel-multi-item .carousel-indicators li,
.mdb-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #33b5e5;
}
.mdb-skin .form-header,
.mdb-skin .card-header {
  background-color: #3cb8e6;
}
.mdb-skin .spinner-primary-color,
.mdb-skin .spinner-primary-color-only {
  border-color: #33b5e5;
}
.mdb-skin .pagination-primary-color .page-item.active .page-link,
.mdb-skin .pagination-primary-color .page-item.active .page-link:focus,
.mdb-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #33b5e5;
}
.mdb-skin .pagination-primary-color .page-link {
  color: #33b5e5;
}
.mdb-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.deep-purple-skin .gradient {
  background: #2e1f49;
  background: linear-gradient(135deg, #2e1f49 0%, #a47fe3 100%);
}
.deep-purple-skin .primary-color {
  background-color: #d0637c !important;
}
.deep-purple-skin .navbar {
  background-color: #7f7e91;
  color: #fff;
}
.deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #9d6eed;
}
.deep-purple-skin .navbar.double-nav a {
  color: #fff;
}
.deep-purple-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.deep-purple-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.deep-purple-skin .page-footer {
  background-color: #7f7e91;
}
.deep-purple-skin .side-nav {
  background-color: #372e5f;
}
.deep-purple-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.deep-purple-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #7e41e8;
}
.deep-purple-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.deep-purple-skin .side-nav .social a:hover .fas,
.deep-purple-skin .side-nav .social a:hover .fab,
.deep-purple-skin .side-nav .social a:hover .far {
  color: #ac85f0 !important;
  transition: all 0.3s linear;
}
.deep-purple-skin .side-nav .collapsible li {
  background-color: transparent;
}
.deep-purple-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.deep-purple-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible li .collapsible-body a:hover, .deep-purple-skin .side-nav .collapsible li .collapsible-body a.active, .deep-purple-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #c9abfc;
}
.deep-purple-skin .side-nav .collapsible li .collapsible-body a .fas,
.deep-purple-skin .side-nav .collapsible li .collapsible-body a .fab,
.deep-purple-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.deep-purple-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .deep-purple-skin .side-nav .collapsible li a:not(.collapsible-header).active, .deep-purple-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #c9abfc !important;
}
.deep-purple-skin .side-nav .sidenav-bg:after, .deep-purple-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(36, 9, 56, 0.88);
}
.deep-purple-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(36, 9, 56, 0.65);
}
.deep-purple-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(36, 9, 56, 0.5);
}
.deep-purple-skin .btn-primary {
  background-color: #ac85f0 !important;
  color: #fff;
}
.deep-purple-skin .btn-primary:hover {
  background-color: #bb9cf3;
  color: #fff;
}
.deep-purple-skin .btn-primary:focus, .deep-purple-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-primary:focus, .deep-purple-skin .btn-primary:active, .deep-purple-skin .btn-primary.active {
  background-color: #6e2ae5;
}
.deep-purple-skin .btn-primary.dropdown-toggle {
  background-color: #ac85f0 !important;
}
.deep-purple-skin .btn-primary.dropdown-toggle:hover, .deep-purple-skin .btn-primary.dropdown-toggle:focus {
  background-color: #bb9cf3 !important;
}
.deep-purple-skin .btn-primary:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #6e2ae5 !important;
}
.deep-purple-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .primary-ic {
  color: #ac85f0 !important;
}
.deep-purple-skin .primary-ic:hover, .deep-purple-skin .primary-ic:focus {
  color: #ac85f0;
}
.deep-purple-skin a.btn:not([href]):not([tabindex]),
.deep-purple-skin a.btn:not([href]):not([tabindex]):focus,
.deep-purple-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.deep-purple-skin table.table a.btn.btn-primary {
  color: #fff;
}
.deep-purple-skin .btn-secondary {
  background-color: #6e4ca3 !important;
  color: #fff;
}
.deep-purple-skin .btn-secondary:hover {
  background-color: #7a58b1;
  color: #fff;
}
.deep-purple-skin .btn-secondary:focus, .deep-purple-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-secondary:focus, .deep-purple-skin .btn-secondary:active, .deep-purple-skin .btn-secondary.active {
  background-color: #3f2c5d;
}
.deep-purple-skin .btn-secondary.dropdown-toggle {
  background-color: #6e4ca3 !important;
}
.deep-purple-skin .btn-secondary.dropdown-toggle:hover, .deep-purple-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #7a58b1 !important;
}
.deep-purple-skin .btn-secondary:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #3f2c5d !important;
}
.deep-purple-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .secondary-ic {
  color: #6e4ca3 !important;
}
.deep-purple-skin .secondary-ic:hover, .deep-purple-skin .secondary-ic:focus {
  color: #6e4ca3;
}
.deep-purple-skin a.btn:not([href]):not([tabindex]),
.deep-purple-skin a.btn:not([href]):not([tabindex]):focus,
.deep-purple-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.deep-purple-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.deep-purple-skin .btn-default {
  background-color: #372e5f !important;
  color: #fff;
}
.deep-purple-skin .btn-default:hover {
  background-color: #413670;
  color: #fff;
}
.deep-purple-skin .btn-default:focus, .deep-purple-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-default:focus, .deep-purple-skin .btn-default:active, .deep-purple-skin .btn-default.active {
  background-color: #0f0d1a;
}
.deep-purple-skin .btn-default.dropdown-toggle {
  background-color: #372e5f !important;
}
.deep-purple-skin .btn-default.dropdown-toggle:hover, .deep-purple-skin .btn-default.dropdown-toggle:focus {
  background-color: #413670 !important;
}
.deep-purple-skin .btn-default:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-default:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0f0d1a !important;
}
.deep-purple-skin .btn-default:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .default-ic {
  color: #372e5f !important;
}
.deep-purple-skin .default-ic:hover, .deep-purple-skin .default-ic:focus {
  color: #372e5f;
}
.deep-purple-skin a.btn:not([href]):not([tabindex]),
.deep-purple-skin a.btn:not([href]):not([tabindex]):focus,
.deep-purple-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.deep-purple-skin table.table a.btn.btn-default {
  color: #fff;
}
.deep-purple-skin .btn-outline-primary {
  border: 2px solid #ac85f0 !important;
  background-color: transparent !important;
  color: #ac85f0 !important;
}
.deep-purple-skin .btn-outline-primary:hover, .deep-purple-skin .btn-outline-primary:focus, .deep-purple-skin .btn-outline-primary:active, .deep-purple-skin .btn-outline-primary:active:focus, .deep-purple-skin .btn-outline-primary.active {
  border-color: #ac85f0 !important;
  background-color: transparent !important;
  color: #ac85f0 !important;
}
.deep-purple-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ac85f0 !important;
}
.deep-purple-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-outline-secondary {
  border: 2px solid #6e4ca3 !important;
  background-color: transparent !important;
  color: #6e4ca3 !important;
}
.deep-purple-skin .btn-outline-secondary:hover, .deep-purple-skin .btn-outline-secondary:focus, .deep-purple-skin .btn-outline-secondary:active, .deep-purple-skin .btn-outline-secondary:active:focus, .deep-purple-skin .btn-outline-secondary.active {
  border-color: #6e4ca3 !important;
  background-color: transparent !important;
  color: #6e4ca3 !important;
}
.deep-purple-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #6e4ca3 !important;
}
.deep-purple-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-outline-default {
  border: 2px solid #372e5f !important;
  background-color: transparent !important;
  color: #372e5f !important;
}
.deep-purple-skin .btn-outline-default:hover, .deep-purple-skin .btn-outline-default:focus, .deep-purple-skin .btn-outline-default:active, .deep-purple-skin .btn-outline-default:active:focus, .deep-purple-skin .btn-outline-default.active {
  border-color: #372e5f !important;
  background-color: transparent !important;
  color: #372e5f !important;
}
.deep-purple-skin .btn-outline-default:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #372e5f !important;
}
.deep-purple-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .card .btn-action {
  background: #372e5f;
}
.deep-purple-skin .card .btn-action:hover, .deep-purple-skin .card .btn-action:focus {
  background-color: #413670 !important;
}
.deep-purple-skin .card .btn-action.active {
  background-color: #0f0d1a !important;
}
.deep-purple-skin .md-outline input[type=text]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=password]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=email]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=url]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=time]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=date]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=tel]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=number]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=search-md]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=search]:focus:not([readonly]),
.deep-purple-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #ac85f0;
  box-shadow: inset 0px 0px 0px 1px #ac85f0;
}
.deep-purple-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #ac85f0;
}
.deep-purple-skin .md-bg input[type=text],
.deep-purple-skin .md-bg input[type=password],
.deep-purple-skin .md-bg input[type=email],
.deep-purple-skin .md-bg input[type=url],
.deep-purple-skin .md-bg input[type=time],
.deep-purple-skin .md-bg input[type=date],
.deep-purple-skin .md-bg input[type=datetime-local],
.deep-purple-skin .md-bg input[type=tel],
.deep-purple-skin .md-bg input[type=number],
.deep-purple-skin .md-bg input[type=search-md],
.deep-purple-skin .md-bg input[type=search],
.deep-purple-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #ac85f0, #ac85f0), linear-gradient(to bottom, #ced4da, #ced4da);
}
.deep-purple-skin input[type=email]:focus:not([readonly]),
.deep-purple-skin input[type=text]:focus:not([readonly]),
.deep-purple-skin input[type=password]:focus:not([readonly]),
.deep-purple-skin input[type=number]:focus:not([readonly]),
.deep-purple-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #ac85f0;
  box-shadow: 0 1px 0 0 #ac85f0;
}
.deep-purple-skin input[type=email]:focus:not([readonly]) + label,
.deep-purple-skin input[type=text]:focus:not([readonly]) + label,
.deep-purple-skin input[type=password]:focus:not([readonly]) + label,
.deep-purple-skin input[type=number]:focus:not([readonly]) + label,
.deep-purple-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #ac85f0;
}
.deep-purple-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #ac85f0;
  border-bottom: 2px solid #ac85f0;
}
.deep-purple-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.deep-purple-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #ac85f0;
  border-color: #ac85f0;
}
.deep-purple-skin .md-form .prefix.active {
  color: #ac85f0;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #ac85f0 !important;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #ac85f0;
  box-shadow: inset 0px 0px 0px 1px #ac85f0;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #ac85f0;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.deep-purple-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.deep-purple-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #ac85f0 !important;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.deep-purple-skin .top-nav-collapse {
  background-color: #7f7e91;
}
.deep-purple-skin .carousel-multi-item .controls-top > a,
.deep-purple-skin .carousel-multi-item .carousel-indicators li,
.deep-purple-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #ac85f0;
}
.deep-purple-skin .form-header,
.deep-purple-skin .card-header {
  background-color: #b28ef1;
}
.deep-purple-skin .spinner-primary-color,
.deep-purple-skin .spinner-primary-color-only {
  border-color: #d0637c;
}
.deep-purple-skin .pagination-primary-color .page-item.active .page-link,
.deep-purple-skin .pagination-primary-color .page-item.active .page-link:focus,
.deep-purple-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #d0637c;
}
.deep-purple-skin .pagination-primary-color .page-link {
  color: #d0637c;
}
.deep-purple-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.navy-blue-skin .gradient {
  background: #222735;
  background: linear-gradient(135deg, #222735 0%, #4f68a6 100%);
}
.navy-blue-skin .primary-color {
  background-color: #309e9e !important;
}
.navy-blue-skin .navbar {
  background-color: #353b50;
  color: #fff;
}
.navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #36b6b6;
}
.navy-blue-skin .navbar.double-nav a {
  color: #fff;
}
.navy-blue-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.navy-blue-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.navy-blue-skin .page-footer {
  background-color: #353b50;
}
.navy-blue-skin .side-nav {
  background-color: #4c5678;
}
.navy-blue-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.navy-blue-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #2b8f8f;
}
.navy-blue-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.navy-blue-skin .side-nav .social a:hover .fas,
.navy-blue-skin .side-nav .social a:hover .fab,
.navy-blue-skin .side-nav .social a:hover .far {
  color: #40c6c6 !important;
  transition: all 0.3s linear;
}
.navy-blue-skin .side-nav .collapsible li {
  background-color: transparent;
}
.navy-blue-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.navy-blue-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible li .collapsible-body a:hover, .navy-blue-skin .side-nav .collapsible li .collapsible-body a.active, .navy-blue-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #40c6c6;
}
.navy-blue-skin .side-nav .collapsible li .collapsible-body a .fas,
.navy-blue-skin .side-nav .collapsible li .collapsible-body a .fab,
.navy-blue-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.navy-blue-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .navy-blue-skin .side-nav .collapsible li a:not(.collapsible-header).active, .navy-blue-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #40c6c6 !important;
}
.navy-blue-skin .side-nav .sidenav-bg:after, .navy-blue-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(14, 15, 32, 0.8);
}
.navy-blue-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(14, 15, 32, 0.65);
}
.navy-blue-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(14, 15, 32, 0.5);
}
.navy-blue-skin .btn-primary {
  background-color: #40c6c6 !important;
  color: #fff;
}
.navy-blue-skin .btn-primary:hover {
  background-color: #54cccc;
  color: #fff;
}
.navy-blue-skin .btn-primary:focus, .navy-blue-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-primary:focus, .navy-blue-skin .btn-primary:active, .navy-blue-skin .btn-primary.active {
  background-color: #257b7b;
}
.navy-blue-skin .btn-primary.dropdown-toggle {
  background-color: #40c6c6 !important;
}
.navy-blue-skin .btn-primary.dropdown-toggle:hover, .navy-blue-skin .btn-primary.dropdown-toggle:focus {
  background-color: #54cccc !important;
}
.navy-blue-skin .btn-primary:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #257b7b !important;
}
.navy-blue-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .primary-ic {
  color: #40c6c6 !important;
}
.navy-blue-skin .primary-ic:hover, .navy-blue-skin .primary-ic:focus {
  color: #40c6c6;
}
.navy-blue-skin a.btn:not([href]):not([tabindex]),
.navy-blue-skin a.btn:not([href]):not([tabindex]):focus,
.navy-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.navy-blue-skin table.table a.btn.btn-primary {
  color: #fff;
}
.navy-blue-skin .btn-secondary {
  background-color: #7e51b4 !important;
  color: #fff;
}
.navy-blue-skin .btn-secondary:hover {
  background-color: #8b63bc;
  color: #fff;
}
.navy-blue-skin .btn-secondary:focus, .navy-blue-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-secondary:focus, .navy-blue-skin .btn-secondary:active, .navy-blue-skin .btn-secondary.active {
  background-color: #4d306f;
}
.navy-blue-skin .btn-secondary.dropdown-toggle {
  background-color: #7e51b4 !important;
}
.navy-blue-skin .btn-secondary.dropdown-toggle:hover, .navy-blue-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #8b63bc !important;
}
.navy-blue-skin .btn-secondary:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #4d306f !important;
}
.navy-blue-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .secondary-ic {
  color: #7e51b4 !important;
}
.navy-blue-skin .secondary-ic:hover, .navy-blue-skin .secondary-ic:focus {
  color: #7e51b4;
}
.navy-blue-skin a.btn:not([href]):not([tabindex]),
.navy-blue-skin a.btn:not([href]):not([tabindex]):focus,
.navy-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.navy-blue-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.navy-blue-skin .btn-default {
  background-color: #293756 !important;
  color: #fff;
}
.navy-blue-skin .btn-default:hover {
  background-color: #314267;
  color: #fff;
}
.navy-blue-skin .btn-default:focus, .navy-blue-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-default:focus, .navy-blue-skin .btn-default:active, .navy-blue-skin .btn-default.active {
  background-color: #080b11;
}
.navy-blue-skin .btn-default.dropdown-toggle {
  background-color: #293756 !important;
}
.navy-blue-skin .btn-default.dropdown-toggle:hover, .navy-blue-skin .btn-default.dropdown-toggle:focus {
  background-color: #314267 !important;
}
.navy-blue-skin .btn-default:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-default:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #080b11 !important;
}
.navy-blue-skin .btn-default:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .default-ic {
  color: #293756 !important;
}
.navy-blue-skin .default-ic:hover, .navy-blue-skin .default-ic:focus {
  color: #293756;
}
.navy-blue-skin a.btn:not([href]):not([tabindex]),
.navy-blue-skin a.btn:not([href]):not([tabindex]):focus,
.navy-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.navy-blue-skin table.table a.btn.btn-default {
  color: #fff;
}
.navy-blue-skin .btn-outline-primary {
  border: 2px solid #40c6c6 !important;
  background-color: transparent !important;
  color: #40c6c6 !important;
}
.navy-blue-skin .btn-outline-primary:hover, .navy-blue-skin .btn-outline-primary:focus, .navy-blue-skin .btn-outline-primary:active, .navy-blue-skin .btn-outline-primary:active:focus, .navy-blue-skin .btn-outline-primary.active {
  border-color: #40c6c6 !important;
  background-color: transparent !important;
  color: #40c6c6 !important;
}
.navy-blue-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #40c6c6 !important;
}
.navy-blue-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-outline-secondary {
  border: 2px solid #7e51b4 !important;
  background-color: transparent !important;
  color: #7e51b4 !important;
}
.navy-blue-skin .btn-outline-secondary:hover, .navy-blue-skin .btn-outline-secondary:focus, .navy-blue-skin .btn-outline-secondary:active, .navy-blue-skin .btn-outline-secondary:active:focus, .navy-blue-skin .btn-outline-secondary.active {
  border-color: #7e51b4 !important;
  background-color: transparent !important;
  color: #7e51b4 !important;
}
.navy-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #7e51b4 !important;
}
.navy-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-outline-default {
  border: 2px solid #293756 !important;
  background-color: transparent !important;
  color: #293756 !important;
}
.navy-blue-skin .btn-outline-default:hover, .navy-blue-skin .btn-outline-default:focus, .navy-blue-skin .btn-outline-default:active, .navy-blue-skin .btn-outline-default:active:focus, .navy-blue-skin .btn-outline-default.active {
  border-color: #293756 !important;
  background-color: transparent !important;
  color: #293756 !important;
}
.navy-blue-skin .btn-outline-default:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #293756 !important;
}
.navy-blue-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .card .btn-action {
  background: #293756;
}
.navy-blue-skin .card .btn-action:hover, .navy-blue-skin .card .btn-action:focus {
  background-color: #314267 !important;
}
.navy-blue-skin .card .btn-action.active {
  background-color: #080b11 !important;
}
.navy-blue-skin .md-outline input[type=text]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=password]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=email]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=url]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=time]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=date]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=tel]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=number]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=search-md]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=search]:focus:not([readonly]),
.navy-blue-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #40c6c6;
  box-shadow: inset 0px 0px 0px 1px #40c6c6;
}
.navy-blue-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #40c6c6;
}
.navy-blue-skin .md-bg input[type=text],
.navy-blue-skin .md-bg input[type=password],
.navy-blue-skin .md-bg input[type=email],
.navy-blue-skin .md-bg input[type=url],
.navy-blue-skin .md-bg input[type=time],
.navy-blue-skin .md-bg input[type=date],
.navy-blue-skin .md-bg input[type=datetime-local],
.navy-blue-skin .md-bg input[type=tel],
.navy-blue-skin .md-bg input[type=number],
.navy-blue-skin .md-bg input[type=search-md],
.navy-blue-skin .md-bg input[type=search],
.navy-blue-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #40c6c6, #40c6c6), linear-gradient(to bottom, #ced4da, #ced4da);
}
.navy-blue-skin input[type=email]:focus:not([readonly]),
.navy-blue-skin input[type=text]:focus:not([readonly]),
.navy-blue-skin input[type=password]:focus:not([readonly]),
.navy-blue-skin input[type=number]:focus:not([readonly]),
.navy-blue-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #40c6c6;
  box-shadow: 0 1px 0 0 #40c6c6;
}
.navy-blue-skin input[type=email]:focus:not([readonly]) + label,
.navy-blue-skin input[type=text]:focus:not([readonly]) + label,
.navy-blue-skin input[type=password]:focus:not([readonly]) + label,
.navy-blue-skin input[type=number]:focus:not([readonly]) + label,
.navy-blue-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #40c6c6;
}
.navy-blue-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #40c6c6;
  border-bottom: 2px solid #40c6c6;
}
.navy-blue-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.navy-blue-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #40c6c6;
  border-color: #40c6c6;
}
.navy-blue-skin .md-form .prefix.active {
  color: #40c6c6;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #40c6c6 !important;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #40c6c6;
  box-shadow: inset 0px 0px 0px 1px #40c6c6;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #40c6c6;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.navy-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.navy-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #40c6c6 !important;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.navy-blue-skin .top-nav-collapse {
  background-color: #353b50;
}
.navy-blue-skin .carousel-multi-item .controls-top > a,
.navy-blue-skin .carousel-multi-item .carousel-indicators li,
.navy-blue-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #40c6c6;
}
.navy-blue-skin .form-header,
.navy-blue-skin .card-header {
  background-color: #48c8c8;
}
.navy-blue-skin .spinner-primary-color,
.navy-blue-skin .spinner-primary-color-only {
  border-color: #309e9e;
}
.navy-blue-skin .pagination-primary-color .page-item.active .page-link,
.navy-blue-skin .pagination-primary-color .page-item.active .page-link:focus,
.navy-blue-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #309e9e;
}
.navy-blue-skin .pagination-primary-color .page-link {
  color: #309e9e;
}
.navy-blue-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.pink-skin .gradient {
  background: #812b5a;
  background: linear-gradient(135deg, #812b5a 0%, #f28cc0 100%);
}
.pink-skin .primary-color {
  background-color: #bd5d70 !important;
}
.pink-skin .navbar {
  background-color: #535466;
  color: #fff;
}
.pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #9192a2;
}
.pink-skin .navbar.double-nav a {
  color: #fff;
}
.pink-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.pink-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.pink-skin .page-footer {
  background-color: #535466;
}
.pink-skin .side-nav {
  background-color: #aa5077;
}
.pink-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.pink-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #76778b;
}
.pink-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.pink-skin .side-nav .social a:hover .fas,
.pink-skin .side-nav .social a:hover .fab,
.pink-skin .side-nav .social a:hover .far {
  color: #9fa0ae !important;
  transition: all 0.3s linear;
}
.pink-skin .side-nav .collapsible li {
  background-color: transparent;
}
.pink-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.pink-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.pink-skin .side-nav .collapsible li .collapsible-body a:hover, .pink-skin .side-nav .collapsible li .collapsible-body a.active, .pink-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #ffb0e6;
}
.pink-skin .side-nav .collapsible li .collapsible-body a .fas,
.pink-skin .side-nav .collapsible li .collapsible-body a .fab,
.pink-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.pink-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.pink-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .pink-skin .side-nav .collapsible li a:not(.collapsible-header).active, .pink-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #ffb0e6 !important;
}
.pink-skin .side-nav .sidenav-bg:after, .pink-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(152, 47, 88, 0.8);
}
.pink-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(152, 47, 88, 0.65);
}
.pink-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(152, 47, 88, 0.5);
}
.pink-skin .btn-primary {
  background-color: #601f39 !important;
  color: #fff;
}
.pink-skin .btn-primary:hover {
  background-color: #732544;
  color: #fff;
}
.pink-skin .btn-primary:focus, .pink-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-primary:focus, .pink-skin .btn-primary:active, .pink-skin .btn-primary.active {
  background-color: #13060b;
}
.pink-skin .btn-primary.dropdown-toggle {
  background-color: #601f39 !important;
}
.pink-skin .btn-primary.dropdown-toggle:hover, .pink-skin .btn-primary.dropdown-toggle:focus {
  background-color: #732544 !important;
}
.pink-skin .btn-primary:not([disabled]):not(.disabled):active, .pink-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #13060b !important;
}
.pink-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .primary-ic {
  color: #601f39 !important;
}
.pink-skin .primary-ic:hover, .pink-skin .primary-ic:focus {
  color: #601f39;
}
.pink-skin a.btn:not([href]):not([tabindex]),
.pink-skin a.btn:not([href]):not([tabindex]):focus,
.pink-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.pink-skin table.table a.btn.btn-primary {
  color: #fff;
}
.pink-skin .btn-secondary {
  background-color: #e7649d !important;
  color: #fff;
}
.pink-skin .btn-secondary:hover {
  background-color: #ea7aab;
  color: #fff;
}
.pink-skin .btn-secondary:focus, .pink-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-secondary:focus, .pink-skin .btn-secondary:active, .pink-skin .btn-secondary.active {
  background-color: #c61f68;
}
.pink-skin .btn-secondary.dropdown-toggle {
  background-color: #e7649d !important;
}
.pink-skin .btn-secondary.dropdown-toggle:hover, .pink-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #ea7aab !important;
}
.pink-skin .btn-secondary:not([disabled]):not(.disabled):active, .pink-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #c61f68 !important;
}
.pink-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .secondary-ic {
  color: #e7649d !important;
}
.pink-skin .secondary-ic:hover, .pink-skin .secondary-ic:focus {
  color: #e7649d;
}
.pink-skin a.btn:not([href]):not([tabindex]),
.pink-skin a.btn:not([href]):not([tabindex]):focus,
.pink-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.pink-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.pink-skin .btn-default {
  background-color: #535466 !important;
  color: #fff;
}
.pink-skin .btn-default:hover {
  background-color: #5e6074;
  color: #fff;
}
.pink-skin .btn-default:focus, .pink-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-default:focus, .pink-skin .btn-default:active, .pink-skin .btn-default.active {
  background-color: #25262e;
}
.pink-skin .btn-default.dropdown-toggle {
  background-color: #535466 !important;
}
.pink-skin .btn-default.dropdown-toggle:hover, .pink-skin .btn-default.dropdown-toggle:focus {
  background-color: #5e6074 !important;
}
.pink-skin .btn-default:not([disabled]):not(.disabled):active, .pink-skin .btn-default:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #25262e !important;
}
.pink-skin .btn-default:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .default-ic {
  color: #535466 !important;
}
.pink-skin .default-ic:hover, .pink-skin .default-ic:focus {
  color: #535466;
}
.pink-skin a.btn:not([href]):not([tabindex]),
.pink-skin a.btn:not([href]):not([tabindex]):focus,
.pink-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.pink-skin table.table a.btn.btn-default {
  color: #fff;
}
.pink-skin .btn-outline-primary {
  border: 2px solid #601f39 !important;
  background-color: transparent !important;
  color: #601f39 !important;
}
.pink-skin .btn-outline-primary:hover, .pink-skin .btn-outline-primary:focus, .pink-skin .btn-outline-primary:active, .pink-skin .btn-outline-primary:active:focus, .pink-skin .btn-outline-primary.active {
  border-color: #601f39 !important;
  background-color: transparent !important;
  color: #601f39 !important;
}
.pink-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .pink-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #601f39 !important;
}
.pink-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-outline-secondary {
  border: 2px solid #e7649d !important;
  background-color: transparent !important;
  color: #e7649d !important;
}
.pink-skin .btn-outline-secondary:hover, .pink-skin .btn-outline-secondary:focus, .pink-skin .btn-outline-secondary:active, .pink-skin .btn-outline-secondary:active:focus, .pink-skin .btn-outline-secondary.active {
  border-color: #e7649d !important;
  background-color: transparent !important;
  color: #e7649d !important;
}
.pink-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .pink-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #e7649d !important;
}
.pink-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-outline-default {
  border: 2px solid #535466 !important;
  background-color: transparent !important;
  color: #535466 !important;
}
.pink-skin .btn-outline-default:hover, .pink-skin .btn-outline-default:focus, .pink-skin .btn-outline-default:active, .pink-skin .btn-outline-default:active:focus, .pink-skin .btn-outline-default.active {
  border-color: #535466 !important;
  background-color: transparent !important;
  color: #535466 !important;
}
.pink-skin .btn-outline-default:not([disabled]):not(.disabled):active, .pink-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #535466 !important;
}
.pink-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .card .btn-action {
  background: #535466;
}
.pink-skin .card .btn-action:hover, .pink-skin .card .btn-action:focus {
  background-color: #5e6074 !important;
}
.pink-skin .card .btn-action.active {
  background-color: #25262e !important;
}
.pink-skin .md-outline input[type=text]:focus:not([readonly]),
.pink-skin .md-outline input[type=password]:focus:not([readonly]),
.pink-skin .md-outline input[type=email]:focus:not([readonly]),
.pink-skin .md-outline input[type=url]:focus:not([readonly]),
.pink-skin .md-outline input[type=time]:focus:not([readonly]),
.pink-skin .md-outline input[type=date]:focus:not([readonly]),
.pink-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.pink-skin .md-outline input[type=tel]:focus:not([readonly]),
.pink-skin .md-outline input[type=number]:focus:not([readonly]),
.pink-skin .md-outline input[type=search-md]:focus:not([readonly]),
.pink-skin .md-outline input[type=search]:focus:not([readonly]),
.pink-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #9fa0ae;
  box-shadow: inset 0px 0px 0px 1px #9fa0ae;
}
.pink-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.pink-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #9fa0ae;
}
.pink-skin .md-bg input[type=text],
.pink-skin .md-bg input[type=password],
.pink-skin .md-bg input[type=email],
.pink-skin .md-bg input[type=url],
.pink-skin .md-bg input[type=time],
.pink-skin .md-bg input[type=date],
.pink-skin .md-bg input[type=datetime-local],
.pink-skin .md-bg input[type=tel],
.pink-skin .md-bg input[type=number],
.pink-skin .md-bg input[type=search-md],
.pink-skin .md-bg input[type=search],
.pink-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #9fa0ae, #9fa0ae), linear-gradient(to bottom, #ced4da, #ced4da);
}
.pink-skin input[type=email]:focus:not([readonly]),
.pink-skin input[type=text]:focus:not([readonly]),
.pink-skin input[type=password]:focus:not([readonly]),
.pink-skin input[type=number]:focus:not([readonly]),
.pink-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #9fa0ae;
  box-shadow: 0 1px 0 0 #9fa0ae;
}
.pink-skin input[type=email]:focus:not([readonly]) + label,
.pink-skin input[type=text]:focus:not([readonly]) + label,
.pink-skin input[type=password]:focus:not([readonly]) + label,
.pink-skin input[type=number]:focus:not([readonly]) + label,
.pink-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #9fa0ae;
}
.pink-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #9fa0ae;
  border-bottom: 2px solid #9fa0ae;
}
.pink-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.pink-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #9fa0ae;
  border-color: #9fa0ae;
}
.pink-skin .md-form .prefix.active {
  color: #9fa0ae;
}
.pink-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #9fa0ae !important;
}
.pink-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #9fa0ae;
  box-shadow: inset 0px 0px 0px 1px #9fa0ae;
}
.pink-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #9fa0ae;
}
.pink-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.pink-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.pink-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #9fa0ae !important;
}
.pink-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.pink-skin .top-nav-collapse {
  background-color: #535466;
}
.pink-skin .carousel-multi-item .controls-top > a,
.pink-skin .carousel-multi-item .carousel-indicators li,
.pink-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #9fa0ae;
}
.pink-skin .form-header,
.pink-skin .card-header {
  background-color: #a5a5b3;
}
.pink-skin .spinner-primary-color,
.pink-skin .spinner-primary-color-only {
  border-color: #bd5d70;
}
.pink-skin .pagination-primary-color .page-item.active .page-link,
.pink-skin .pagination-primary-color .page-item.active .page-link:focus,
.pink-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #bd5d70;
}
.pink-skin .pagination-primary-color .page-link {
  color: #bd5d70;
}
.pink-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.indigo-skin .gradient {
  background: #272d6e;
  background: linear-gradient(135deg, #272d6e 0%, #90a8ff 100%);
}
.indigo-skin .primary-color {
  background-color: #7f82c5 !important;
}
.indigo-skin .navbar {
  background-color: #9095aa;
  color: #fff;
}
.indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #fd4d79;
}
.indigo-skin .navbar.double-nav a {
  color: #fff;
}
.indigo-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.indigo-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.indigo-skin .page-footer {
  background-color: #9095aa;
}
.indigo-skin .side-nav {
  background-color: #404f9f;
}
.indigo-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.indigo-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #fc1a53;
}
.indigo-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.indigo-skin .side-nav .social a:hover .fas,
.indigo-skin .side-nav .social a:hover .fab,
.indigo-skin .side-nav .social a:hover .far {
  color: #fd668c !important;
  transition: all 0.3s linear;
}
.indigo-skin .side-nav .collapsible li {
  background-color: transparent;
}
.indigo-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.indigo-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.indigo-skin .side-nav .collapsible li .collapsible-body a:hover, .indigo-skin .side-nav .collapsible li .collapsible-body a.active, .indigo-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #ff89ac;
}
.indigo-skin .side-nav .collapsible li .collapsible-body a .fas,
.indigo-skin .side-nav .collapsible li .collapsible-body a .fab,
.indigo-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.indigo-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.indigo-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .indigo-skin .side-nav .collapsible li a:not(.collapsible-header).active, .indigo-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #ff89ac !important;
}
.indigo-skin .side-nav .sidenav-bg:after, .indigo-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(35, 65, 134, 0.8);
}
.indigo-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(35, 65, 134, 0.65);
}
.indigo-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(35, 65, 134, 0.5);
}
.indigo-skin .btn-primary {
  background-color: #fd668c !important;
  color: #fff;
}
.indigo-skin .btn-primary:hover {
  background-color: #fd7f9f;
  color: #fff;
}
.indigo-skin .btn-primary:focus, .indigo-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-primary:focus, .indigo-skin .btn-primary:active, .indigo-skin .btn-primary.active {
  background-color: #fa0341;
}
.indigo-skin .btn-primary.dropdown-toggle {
  background-color: #fd668c !important;
}
.indigo-skin .btn-primary.dropdown-toggle:hover, .indigo-skin .btn-primary.dropdown-toggle:focus {
  background-color: #fd7f9f !important;
}
.indigo-skin .btn-primary:not([disabled]):not(.disabled):active, .indigo-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #fa0341 !important;
}
.indigo-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .primary-ic {
  color: #fd668c !important;
}
.indigo-skin .primary-ic:hover, .indigo-skin .primary-ic:focus {
  color: #fd668c;
}
.indigo-skin a.btn:not([href]):not([tabindex]),
.indigo-skin a.btn:not([href]):not([tabindex]):focus,
.indigo-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.indigo-skin table.table a.btn.btn-primary {
  color: #fff;
}
.indigo-skin .btn-secondary {
  background-color: #3c61ae !important;
  color: #fff;
}
.indigo-skin .btn-secondary:hover {
  background-color: #456cbf;
  color: #fff;
}
.indigo-skin .btn-secondary:focus, .indigo-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-secondary:focus, .indigo-skin .btn-secondary:active, .indigo-skin .btn-secondary.active {
  background-color: #223762;
}
.indigo-skin .btn-secondary.dropdown-toggle {
  background-color: #3c61ae !important;
}
.indigo-skin .btn-secondary.dropdown-toggle:hover, .indigo-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #456cbf !important;
}
.indigo-skin .btn-secondary:not([disabled]):not(.disabled):active, .indigo-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #223762 !important;
}
.indigo-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .secondary-ic {
  color: #3c61ae !important;
}
.indigo-skin .secondary-ic:hover, .indigo-skin .secondary-ic:focus {
  color: #3c61ae;
}
.indigo-skin a.btn:not([href]):not([tabindex]),
.indigo-skin a.btn:not([href]):not([tabindex]):focus,
.indigo-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.indigo-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.indigo-skin .btn-default {
  background-color: #9095aa !important;
  color: #fff;
}
.indigo-skin .btn-default:hover {
  background-color: #9ea3b5;
  color: #fff;
}
.indigo-skin .btn-default:focus, .indigo-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-default:focus, .indigo-skin .btn-default:active, .indigo-skin .btn-default.active {
  background-color: #5c6178;
}
.indigo-skin .btn-default.dropdown-toggle {
  background-color: #9095aa !important;
}
.indigo-skin .btn-default.dropdown-toggle:hover, .indigo-skin .btn-default.dropdown-toggle:focus {
  background-color: #9ea3b5 !important;
}
.indigo-skin .btn-default:not([disabled]):not(.disabled):active, .indigo-skin .btn-default:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #5c6178 !important;
}
.indigo-skin .btn-default:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .default-ic {
  color: #9095aa !important;
}
.indigo-skin .default-ic:hover, .indigo-skin .default-ic:focus {
  color: #9095aa;
}
.indigo-skin a.btn:not([href]):not([tabindex]),
.indigo-skin a.btn:not([href]):not([tabindex]):focus,
.indigo-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.indigo-skin table.table a.btn.btn-default {
  color: #fff;
}
.indigo-skin .btn-outline-primary {
  border: 2px solid #fd668c !important;
  background-color: transparent !important;
  color: #fd668c !important;
}
.indigo-skin .btn-outline-primary:hover, .indigo-skin .btn-outline-primary:focus, .indigo-skin .btn-outline-primary:active, .indigo-skin .btn-outline-primary:active:focus, .indigo-skin .btn-outline-primary.active {
  border-color: #fd668c !important;
  background-color: transparent !important;
  color: #fd668c !important;
}
.indigo-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .indigo-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #fd668c !important;
}
.indigo-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-outline-secondary {
  border: 2px solid #3c61ae !important;
  background-color: transparent !important;
  color: #3c61ae !important;
}
.indigo-skin .btn-outline-secondary:hover, .indigo-skin .btn-outline-secondary:focus, .indigo-skin .btn-outline-secondary:active, .indigo-skin .btn-outline-secondary:active:focus, .indigo-skin .btn-outline-secondary.active {
  border-color: #3c61ae !important;
  background-color: transparent !important;
  color: #3c61ae !important;
}
.indigo-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .indigo-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #3c61ae !important;
}
.indigo-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-outline-default {
  border: 2px solid #9095aa !important;
  background-color: transparent !important;
  color: #9095aa !important;
}
.indigo-skin .btn-outline-default:hover, .indigo-skin .btn-outline-default:focus, .indigo-skin .btn-outline-default:active, .indigo-skin .btn-outline-default:active:focus, .indigo-skin .btn-outline-default.active {
  border-color: #9095aa !important;
  background-color: transparent !important;
  color: #9095aa !important;
}
.indigo-skin .btn-outline-default:not([disabled]):not(.disabled):active, .indigo-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #9095aa !important;
}
.indigo-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .card .btn-action {
  background: #9095aa;
}
.indigo-skin .card .btn-action:hover, .indigo-skin .card .btn-action:focus {
  background-color: #9ea3b5 !important;
}
.indigo-skin .card .btn-action.active {
  background-color: #5c6178 !important;
}
.indigo-skin .md-outline input[type=text]:focus:not([readonly]),
.indigo-skin .md-outline input[type=password]:focus:not([readonly]),
.indigo-skin .md-outline input[type=email]:focus:not([readonly]),
.indigo-skin .md-outline input[type=url]:focus:not([readonly]),
.indigo-skin .md-outline input[type=time]:focus:not([readonly]),
.indigo-skin .md-outline input[type=date]:focus:not([readonly]),
.indigo-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.indigo-skin .md-outline input[type=tel]:focus:not([readonly]),
.indigo-skin .md-outline input[type=number]:focus:not([readonly]),
.indigo-skin .md-outline input[type=search-md]:focus:not([readonly]),
.indigo-skin .md-outline input[type=search]:focus:not([readonly]),
.indigo-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #fd668c;
  box-shadow: inset 0px 0px 0px 1px #fd668c;
}
.indigo-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.indigo-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #fd668c;
}
.indigo-skin .md-bg input[type=text],
.indigo-skin .md-bg input[type=password],
.indigo-skin .md-bg input[type=email],
.indigo-skin .md-bg input[type=url],
.indigo-skin .md-bg input[type=time],
.indigo-skin .md-bg input[type=date],
.indigo-skin .md-bg input[type=datetime-local],
.indigo-skin .md-bg input[type=tel],
.indigo-skin .md-bg input[type=number],
.indigo-skin .md-bg input[type=search-md],
.indigo-skin .md-bg input[type=search],
.indigo-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #fd668c, #fd668c), linear-gradient(to bottom, #ced4da, #ced4da);
}
.indigo-skin input[type=email]:focus:not([readonly]),
.indigo-skin input[type=text]:focus:not([readonly]),
.indigo-skin input[type=password]:focus:not([readonly]),
.indigo-skin input[type=number]:focus:not([readonly]),
.indigo-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #fd668c;
  box-shadow: 0 1px 0 0 #fd668c;
}
.indigo-skin input[type=email]:focus:not([readonly]) + label,
.indigo-skin input[type=text]:focus:not([readonly]) + label,
.indigo-skin input[type=password]:focus:not([readonly]) + label,
.indigo-skin input[type=number]:focus:not([readonly]) + label,
.indigo-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #fd668c;
}
.indigo-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #fd668c;
  border-bottom: 2px solid #fd668c;
}
.indigo-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.indigo-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #fd668c;
  border-color: #fd668c;
}
.indigo-skin .md-form .prefix.active {
  color: #fd668c;
}
.indigo-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #fd668c !important;
}
.indigo-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #fd668c;
  box-shadow: inset 0px 0px 0px 1px #fd668c;
}
.indigo-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #fd668c;
}
.indigo-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.indigo-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.indigo-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #fd668c !important;
}
.indigo-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.indigo-skin .top-nav-collapse {
  background-color: #9095aa;
}
.indigo-skin .carousel-multi-item .controls-top > a,
.indigo-skin .carousel-multi-item .carousel-indicators li,
.indigo-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #fd668c;
}
.indigo-skin .form-header,
.indigo-skin .card-header {
  background-color: #fd7094;
}
.indigo-skin .spinner-primary-color,
.indigo-skin .spinner-primary-color-only {
  border-color: #7f82c5;
}
.indigo-skin .pagination-primary-color .page-item.active .page-link,
.indigo-skin .pagination-primary-color .page-item.active .page-link:focus,
.indigo-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #7f82c5;
}
.indigo-skin .pagination-primary-color .page-link {
  color: #7f82c5;
}
.indigo-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.light-blue-skin .gradient {
  background: #69adf4;
  background: linear-gradient(135deg, #69adf4 0%, #69adf4 100%);
}
.light-blue-skin .primary-color {
  background-color: #3d799c !important;
}
.light-blue-skin .navbar {
  background-color: #3f5c80;
  color: #fff;
}
.light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #a5b6be;
}
.light-blue-skin .navbar.double-nav a {
  color: #fff;
}
.light-blue-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.light-blue-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.light-blue-skin .page-footer {
  background-color: #3f5c80;
}
.light-blue-skin .side-nav {
  background-color: #6a9ed3;
}
.light-blue-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.light-blue-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #889ea9;
}
.light-blue-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.light-blue-skin .side-nav .social a:hover .fas,
.light-blue-skin .side-nav .social a:hover .fab,
.light-blue-skin .side-nav .social a:hover .far {
  color: #b4c2c9 !important;
  transition: all 0.3s linear;
}
.light-blue-skin .side-nav .collapsible li {
  background-color: transparent;
}
.light-blue-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.light-blue-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible li .collapsible-body a:hover, .light-blue-skin .side-nav .collapsible li .collapsible-body a.active, .light-blue-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #aadeff;
}
.light-blue-skin .side-nav .collapsible li .collapsible-body a .fas,
.light-blue-skin .side-nav .collapsible li .collapsible-body a .fab,
.light-blue-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.light-blue-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .light-blue-skin .side-nav .collapsible li a:not(.collapsible-header).active, .light-blue-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #aadeff !important;
}
.light-blue-skin .side-nav .sidenav-bg:after, .light-blue-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(87, 134, 180, 0.8);
}
.light-blue-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(87, 134, 180, 0.65);
}
.light-blue-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(87, 134, 180, 0.5);
}
.light-blue-skin .btn-primary {
  background-color: #ff4a67 !important;
  color: #fff;
}
.light-blue-skin .btn-primary:hover {
  background-color: #ff647c;
  color: #fff;
}
.light-blue-skin .btn-primary:focus, .light-blue-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-primary:focus, .light-blue-skin .btn-primary:active, .light-blue-skin .btn-primary.active {
  background-color: #e30024;
}
.light-blue-skin .btn-primary.dropdown-toggle {
  background-color: #ff4a67 !important;
}
.light-blue-skin .btn-primary.dropdown-toggle:hover, .light-blue-skin .btn-primary.dropdown-toggle:focus {
  background-color: #ff647c !important;
}
.light-blue-skin .btn-primary:not([disabled]):not(.disabled):active, .light-blue-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #e30024 !important;
}
.light-blue-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .primary-ic {
  color: #ff4a67 !important;
}
.light-blue-skin .primary-ic:hover, .light-blue-skin .primary-ic:focus {
  color: #ff4a67;
}
.light-blue-skin a.btn:not([href]):not([tabindex]),
.light-blue-skin a.btn:not([href]):not([tabindex]):focus,
.light-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.light-blue-skin table.table a.btn.btn-primary {
  color: #fff;
}
.light-blue-skin .btn-secondary {
  background-color: #64c3f0 !important;
  color: #fff;
}
.light-blue-skin .btn-secondary:hover {
  background-color: #7bccf2;
  color: #fff;
}
.light-blue-skin .btn-secondary:focus, .light-blue-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-secondary:focus, .light-blue-skin .btn-secondary:active, .light-blue-skin .btn-secondary.active {
  background-color: #159ad9;
}
.light-blue-skin .btn-secondary.dropdown-toggle {
  background-color: #64c3f0 !important;
}
.light-blue-skin .btn-secondary.dropdown-toggle:hover, .light-blue-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #7bccf2 !important;
}
.light-blue-skin .btn-secondary:not([disabled]):not(.disabled):active, .light-blue-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #159ad9 !important;
}
.light-blue-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .secondary-ic {
  color: #64c3f0 !important;
}
.light-blue-skin .secondary-ic:hover, .light-blue-skin .secondary-ic:focus {
  color: #64c3f0;
}
.light-blue-skin a.btn:not([href]):not([tabindex]),
.light-blue-skin a.btn:not([href]):not([tabindex]):focus,
.light-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.light-blue-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.light-blue-skin .btn-default {
  background-color: #2d486a !important;
  color: #fff;
}
.light-blue-skin .btn-default:hover {
  background-color: #35547c;
  color: #fff;
}
.light-blue-skin .btn-default:focus, .light-blue-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-default:focus, .light-blue-skin .btn-default:active, .light-blue-skin .btn-default.active {
  background-color: #0f1722;
}
.light-blue-skin .btn-default.dropdown-toggle {
  background-color: #2d486a !important;
}
.light-blue-skin .btn-default.dropdown-toggle:hover, .light-blue-skin .btn-default.dropdown-toggle:focus {
  background-color: #35547c !important;
}
.light-blue-skin .btn-default:not([disabled]):not(.disabled):active, .light-blue-skin .btn-default:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0f1722 !important;
}
.light-blue-skin .btn-default:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .default-ic {
  color: #2d486a !important;
}
.light-blue-skin .default-ic:hover, .light-blue-skin .default-ic:focus {
  color: #2d486a;
}
.light-blue-skin a.btn:not([href]):not([tabindex]),
.light-blue-skin a.btn:not([href]):not([tabindex]):focus,
.light-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.light-blue-skin table.table a.btn.btn-default {
  color: #fff;
}
.light-blue-skin .btn-outline-primary {
  border: 2px solid #ff4a67 !important;
  background-color: transparent !important;
  color: #ff4a67 !important;
}
.light-blue-skin .btn-outline-primary:hover, .light-blue-skin .btn-outline-primary:focus, .light-blue-skin .btn-outline-primary:active, .light-blue-skin .btn-outline-primary:active:focus, .light-blue-skin .btn-outline-primary.active {
  border-color: #ff4a67 !important;
  background-color: transparent !important;
  color: #ff4a67 !important;
}
.light-blue-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .light-blue-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ff4a67 !important;
}
.light-blue-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-outline-secondary {
  border: 2px solid #64c3f0 !important;
  background-color: transparent !important;
  color: #64c3f0 !important;
}
.light-blue-skin .btn-outline-secondary:hover, .light-blue-skin .btn-outline-secondary:focus, .light-blue-skin .btn-outline-secondary:active, .light-blue-skin .btn-outline-secondary:active:focus, .light-blue-skin .btn-outline-secondary.active {
  border-color: #64c3f0 !important;
  background-color: transparent !important;
  color: #64c3f0 !important;
}
.light-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .light-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #64c3f0 !important;
}
.light-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-outline-default {
  border: 2px solid #2d486a !important;
  background-color: transparent !important;
  color: #2d486a !important;
}
.light-blue-skin .btn-outline-default:hover, .light-blue-skin .btn-outline-default:focus, .light-blue-skin .btn-outline-default:active, .light-blue-skin .btn-outline-default:active:focus, .light-blue-skin .btn-outline-default.active {
  border-color: #2d486a !important;
  background-color: transparent !important;
  color: #2d486a !important;
}
.light-blue-skin .btn-outline-default:not([disabled]):not(.disabled):active, .light-blue-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #2d486a !important;
}
.light-blue-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .card .btn-action {
  background: #2d486a;
}
.light-blue-skin .card .btn-action:hover, .light-blue-skin .card .btn-action:focus {
  background-color: #35547c !important;
}
.light-blue-skin .card .btn-action.active {
  background-color: #0f1722 !important;
}
.light-blue-skin .md-outline input[type=text]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=password]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=email]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=url]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=time]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=date]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=tel]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=number]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=search-md]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=search]:focus:not([readonly]),
.light-blue-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #b4c2c9;
  box-shadow: inset 0px 0px 0px 1px #b4c2c9;
}
.light-blue-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.light-blue-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #b4c2c9;
}
.light-blue-skin .md-bg input[type=text],
.light-blue-skin .md-bg input[type=password],
.light-blue-skin .md-bg input[type=email],
.light-blue-skin .md-bg input[type=url],
.light-blue-skin .md-bg input[type=time],
.light-blue-skin .md-bg input[type=date],
.light-blue-skin .md-bg input[type=datetime-local],
.light-blue-skin .md-bg input[type=tel],
.light-blue-skin .md-bg input[type=number],
.light-blue-skin .md-bg input[type=search-md],
.light-blue-skin .md-bg input[type=search],
.light-blue-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #b4c2c9, #b4c2c9), linear-gradient(to bottom, #ced4da, #ced4da);
}
.light-blue-skin input[type=email]:focus:not([readonly]),
.light-blue-skin input[type=text]:focus:not([readonly]),
.light-blue-skin input[type=password]:focus:not([readonly]),
.light-blue-skin input[type=number]:focus:not([readonly]),
.light-blue-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #b4c2c9;
  box-shadow: 0 1px 0 0 #b4c2c9;
}
.light-blue-skin input[type=email]:focus:not([readonly]) + label,
.light-blue-skin input[type=text]:focus:not([readonly]) + label,
.light-blue-skin input[type=password]:focus:not([readonly]) + label,
.light-blue-skin input[type=number]:focus:not([readonly]) + label,
.light-blue-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #b4c2c9;
}
.light-blue-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #b4c2c9;
  border-bottom: 2px solid #b4c2c9;
}
.light-blue-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.light-blue-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #b4c2c9;
  border-color: #b4c2c9;
}
.light-blue-skin .md-form .prefix.active {
  color: #b4c2c9;
}
.light-blue-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #b4c2c9 !important;
}
.light-blue-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #b4c2c9;
  box-shadow: inset 0px 0px 0px 1px #b4c2c9;
}
.light-blue-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #b4c2c9;
}
.light-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.light-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.light-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #b4c2c9 !important;
}
.light-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.light-blue-skin .top-nav-collapse {
  background-color: #3f5c80;
}
.light-blue-skin .carousel-multi-item .controls-top > a,
.light-blue-skin .carousel-multi-item .carousel-indicators li,
.light-blue-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #b4c2c9;
}
.light-blue-skin .form-header,
.light-blue-skin .card-header {
  background-color: #bac7cd;
}
.light-blue-skin .spinner-primary-color,
.light-blue-skin .spinner-primary-color-only {
  border-color: #3d799c;
}
.light-blue-skin .pagination-primary-color .page-item.active .page-link,
.light-blue-skin .pagination-primary-color .page-item.active .page-link:focus,
.light-blue-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #3d799c;
}
.light-blue-skin .pagination-primary-color .page-link {
  color: #3d799c;
}
.light-blue-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.grey-skin .gradient {
  background: #383838;
  background: linear-gradient(135deg, #383838 0%, #a5a4a4 100%);
}
.grey-skin .primary-color {
  background-color: #2b2b2b !important;
}
.grey-skin .navbar {
  background-color: #44474b;
  color: #fff;
}
.grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #5eb7ee;
}
.grey-skin .navbar.double-nav a {
  color: #fff;
}
.grey-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.grey-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.grey-skin .page-footer {
  background-color: #44474b;
}
.grey-skin .side-nav {
  background-color: #a5a4a4;
}
.grey-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.grey-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #30a2e9;
}
.grey-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.grey-skin .side-nav .social a:hover .fas,
.grey-skin .side-nav .social a:hover .fab,
.grey-skin .side-nav .social a:hover .far {
  color: #75c1f0 !important;
  transition: all 0.3s linear;
}
.grey-skin .side-nav .collapsible li {
  background-color: transparent;
}
.grey-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.grey-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.grey-skin .side-nav .collapsible li .collapsible-body a:hover, .grey-skin .side-nav .collapsible li .collapsible-body a.active, .grey-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #afdffc;
}
.grey-skin .side-nav .collapsible li .collapsible-body a .fas,
.grey-skin .side-nav .collapsible li .collapsible-body a .fab,
.grey-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.grey-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.grey-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .grey-skin .side-nav .collapsible li a:not(.collapsible-header).active, .grey-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #afdffc !important;
}
.grey-skin .side-nav .sidenav-bg:after, .grey-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(121, 121, 121, 0.8);
}
.grey-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(121, 121, 121, 0.65);
}
.grey-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(121, 121, 121, 0.5);
}
.grey-skin .btn-primary {
  background-color: #46aded !important;
  color: #fff;
}
.grey-skin .btn-primary:hover {
  background-color: #5db7ef;
  color: #fff;
}
.grey-skin .btn-primary:focus, .grey-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-primary:focus, .grey-skin .btn-primary:active, .grey-skin .btn-primary.active {
  background-color: #127abb;
}
.grey-skin .btn-primary.dropdown-toggle {
  background-color: #46aded !important;
}
.grey-skin .btn-primary.dropdown-toggle:hover, .grey-skin .btn-primary.dropdown-toggle:focus {
  background-color: #5db7ef !important;
}
.grey-skin .btn-primary:not([disabled]):not(.disabled):active, .grey-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #127abb !important;
}
.grey-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .primary-ic {
  color: #46aded !important;
}
.grey-skin .primary-ic:hover, .grey-skin .primary-ic:focus {
  color: #46aded;
}
.grey-skin a.btn:not([href]):not([tabindex]),
.grey-skin a.btn:not([href]):not([tabindex]):focus,
.grey-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.grey-skin table.table a.btn.btn-primary {
  color: #fff;
}
.grey-skin .btn-secondary {
  background-color: #7d8488 !important;
  color: #fff;
}
.grey-skin .btn-secondary:hover {
  background-color: #8a9194;
  color: #fff;
}
.grey-skin .btn-secondary:focus, .grey-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-secondary:focus, .grey-skin .btn-secondary:active, .grey-skin .btn-secondary.active {
  background-color: #4c5053;
}
.grey-skin .btn-secondary.dropdown-toggle {
  background-color: #7d8488 !important;
}
.grey-skin .btn-secondary.dropdown-toggle:hover, .grey-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #8a9194 !important;
}
.grey-skin .btn-secondary:not([disabled]):not(.disabled):active, .grey-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #4c5053 !important;
}
.grey-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .secondary-ic {
  color: #7d8488 !important;
}
.grey-skin .secondary-ic:hover, .grey-skin .secondary-ic:focus {
  color: #7d8488;
}
.grey-skin a.btn:not([href]):not([tabindex]),
.grey-skin a.btn:not([href]):not([tabindex]):focus,
.grey-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.grey-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.grey-skin .btn-default {
  background-color: #353637 !important;
  color: #fff;
}
.grey-skin .btn-default:hover {
  background-color: #424344;
  color: #fff;
}
.grey-skin .btn-default:focus, .grey-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-default:focus, .grey-skin .btn-default:active, .grey-skin .btn-default.active {
  background-color: #030303;
}
.grey-skin .btn-default.dropdown-toggle {
  background-color: #353637 !important;
}
.grey-skin .btn-default.dropdown-toggle:hover, .grey-skin .btn-default.dropdown-toggle:focus {
  background-color: #424344 !important;
}
.grey-skin .btn-default:not([disabled]):not(.disabled):active, .grey-skin .btn-default:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #030303 !important;
}
.grey-skin .btn-default:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .default-ic {
  color: #353637 !important;
}
.grey-skin .default-ic:hover, .grey-skin .default-ic:focus {
  color: #353637;
}
.grey-skin a.btn:not([href]):not([tabindex]),
.grey-skin a.btn:not([href]):not([tabindex]):focus,
.grey-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.grey-skin table.table a.btn.btn-default {
  color: #fff;
}
.grey-skin .btn-outline-primary {
  border: 2px solid #46aded !important;
  background-color: transparent !important;
  color: #46aded !important;
}
.grey-skin .btn-outline-primary:hover, .grey-skin .btn-outline-primary:focus, .grey-skin .btn-outline-primary:active, .grey-skin .btn-outline-primary:active:focus, .grey-skin .btn-outline-primary.active {
  border-color: #46aded !important;
  background-color: transparent !important;
  color: #46aded !important;
}
.grey-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .grey-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #46aded !important;
}
.grey-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-outline-secondary {
  border: 2px solid #7d8488 !important;
  background-color: transparent !important;
  color: #7d8488 !important;
}
.grey-skin .btn-outline-secondary:hover, .grey-skin .btn-outline-secondary:focus, .grey-skin .btn-outline-secondary:active, .grey-skin .btn-outline-secondary:active:focus, .grey-skin .btn-outline-secondary.active {
  border-color: #7d8488 !important;
  background-color: transparent !important;
  color: #7d8488 !important;
}
.grey-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .grey-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #7d8488 !important;
}
.grey-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-outline-default {
  border: 2px solid #353637 !important;
  background-color: transparent !important;
  color: #353637 !important;
}
.grey-skin .btn-outline-default:hover, .grey-skin .btn-outline-default:focus, .grey-skin .btn-outline-default:active, .grey-skin .btn-outline-default:active:focus, .grey-skin .btn-outline-default.active {
  border-color: #353637 !important;
  background-color: transparent !important;
  color: #353637 !important;
}
.grey-skin .btn-outline-default:not([disabled]):not(.disabled):active, .grey-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #353637 !important;
}
.grey-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .card .btn-action {
  background: #353637;
}
.grey-skin .card .btn-action:hover, .grey-skin .card .btn-action:focus {
  background-color: #424344 !important;
}
.grey-skin .card .btn-action.active {
  background-color: #030303 !important;
}
.grey-skin .md-outline input[type=text]:focus:not([readonly]),
.grey-skin .md-outline input[type=password]:focus:not([readonly]),
.grey-skin .md-outline input[type=email]:focus:not([readonly]),
.grey-skin .md-outline input[type=url]:focus:not([readonly]),
.grey-skin .md-outline input[type=time]:focus:not([readonly]),
.grey-skin .md-outline input[type=date]:focus:not([readonly]),
.grey-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.grey-skin .md-outline input[type=tel]:focus:not([readonly]),
.grey-skin .md-outline input[type=number]:focus:not([readonly]),
.grey-skin .md-outline input[type=search-md]:focus:not([readonly]),
.grey-skin .md-outline input[type=search]:focus:not([readonly]),
.grey-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #75c1f0;
  box-shadow: inset 0px 0px 0px 1px #75c1f0;
}
.grey-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.grey-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #75c1f0;
}
.grey-skin .md-bg input[type=text],
.grey-skin .md-bg input[type=password],
.grey-skin .md-bg input[type=email],
.grey-skin .md-bg input[type=url],
.grey-skin .md-bg input[type=time],
.grey-skin .md-bg input[type=date],
.grey-skin .md-bg input[type=datetime-local],
.grey-skin .md-bg input[type=tel],
.grey-skin .md-bg input[type=number],
.grey-skin .md-bg input[type=search-md],
.grey-skin .md-bg input[type=search],
.grey-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #75c1f0, #75c1f0), linear-gradient(to bottom, #ced4da, #ced4da);
}
.grey-skin input[type=email]:focus:not([readonly]),
.grey-skin input[type=text]:focus:not([readonly]),
.grey-skin input[type=password]:focus:not([readonly]),
.grey-skin input[type=number]:focus:not([readonly]),
.grey-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #75c1f0;
  box-shadow: 0 1px 0 0 #75c1f0;
}
.grey-skin input[type=email]:focus:not([readonly]) + label,
.grey-skin input[type=text]:focus:not([readonly]) + label,
.grey-skin input[type=password]:focus:not([readonly]) + label,
.grey-skin input[type=number]:focus:not([readonly]) + label,
.grey-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #75c1f0;
}
.grey-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #75c1f0;
  border-bottom: 2px solid #75c1f0;
}
.grey-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.grey-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #75c1f0;
  border-color: #75c1f0;
}
.grey-skin .md-form .prefix.active {
  color: #75c1f0;
}
.grey-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #75c1f0 !important;
}
.grey-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #75c1f0;
  box-shadow: inset 0px 0px 0px 1px #75c1f0;
}
.grey-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #75c1f0;
}
.grey-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.grey-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.grey-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #75c1f0 !important;
}
.grey-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.grey-skin .top-nav-collapse {
  background-color: #44474b;
}
.grey-skin .carousel-multi-item .controls-top > a,
.grey-skin .carousel-multi-item .carousel-indicators li,
.grey-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #75c1f0;
}
.grey-skin .form-header,
.grey-skin .card-header {
  background-color: #7ec5f1;
}
.grey-skin .spinner-primary-color,
.grey-skin .spinner-primary-color-only {
  border-color: #2b2b2b;
}
.grey-skin .pagination-primary-color .page-item.active .page-link,
.grey-skin .pagination-primary-color .page-item.active .page-link:focus,
.grey-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #2b2b2b;
}
.grey-skin .pagination-primary-color .page-link {
  color: #2b2b2b;
}
.grey-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.PGA-skin .gradient {
  background: #fff;
  background: linear-gradient(135deg, #fff 0%, #fff 100%);
}
.PGA-skin .primary-color {
  background-color: #4682b4 !important;
}
.PGA-skin .navbar {
  background-color: #fff;
  color: #2d5373;
}
.PGA-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.PGA-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .PGA-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .PGA-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #3f75a2;
}
.PGA-skin .navbar.double-nav a {
  color: #2d5373;
}
.PGA-skin .navbar form .md-form .form-control {
  color: #2d5373;
  font-weight: 300;
}
.PGA-skin .navbar form .md-form .form-control::placeholder {
  color: #2d5373;
}
.PGA-skin .page-footer {
  background-color: #fff;
}
.PGA-skin .side-nav {
  background-color: #fff;
}
.PGA-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.PGA-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #315a7d;
}
.PGA-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.PGA-skin .side-nav .social a:hover .fas,
.PGA-skin .side-nav .social a:hover .fab,
.PGA-skin .side-nav .social a:hover .far {
  color: #4682b4 !important;
  transition: all 0.3s linear;
}
.PGA-skin .side-nav .collapsible li {
  background-color: transparent;
}
.PGA-skin .side-nav .collapsible li .collapsible-header {
  color: #2d5373;
  transition: all 0.3s linear;
}
.PGA-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: #fff;
}
.PGA-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: #fff;
}
.PGA-skin .side-nav .collapsible li .collapsible-body a {
  color: #2d5373;
}
.PGA-skin .side-nav .collapsible li .collapsible-body a:hover, .PGA-skin .side-nav .collapsible li .collapsible-body a.active, .PGA-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #fff;
}
.PGA-skin .side-nav .collapsible li .collapsible-body a .fas,
.PGA-skin .side-nav .collapsible li .collapsible-body a .fab,
.PGA-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #2d5373;
}
.PGA-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #2d5373;
  transition: all 0.3s linear;
}
.PGA-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .PGA-skin .side-nav .collapsible li a:not(.collapsible-header).active, .PGA-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #fff !important;
}
.PGA-skin .side-nav .sidenav-bg:after, .PGA-skin .side-nav .sidenav-bg.mask-strong:after {
  background: #fff;
}
.PGA-skin .side-nav .sidenav-bg.mask-light:after {
  background: #fff;
}
.PGA-skin .side-nav .sidenav-bg.mask-slight:after {
  background: #fff;
}
.PGA-skin .btn-primary {
  background-color: #4682b4 !important;
  color: #fff;
}
.PGA-skin .btn-primary:hover {
  background-color: #568ebd;
  color: #fff;
}
.PGA-skin .btn-primary:focus, .PGA-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.PGA-skin .btn-primary:focus, .PGA-skin .btn-primary:active, .PGA-skin .btn-primary.active {
  background-color: #294d6b;
}
.PGA-skin .btn-primary.dropdown-toggle {
  background-color: #4682b4 !important;
}
.PGA-skin .btn-primary.dropdown-toggle:hover, .PGA-skin .btn-primary.dropdown-toggle:focus {
  background-color: #568ebd !important;
}
.PGA-skin .btn-primary:not([disabled]):not(.disabled):active, .PGA-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .PGA-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #294d6b !important;
}
.PGA-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .PGA-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .PGA-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.PGA-skin .primary-ic {
  color: #4682b4 !important;
}
.PGA-skin .primary-ic:hover, .PGA-skin .primary-ic:focus {
  color: #4682b4;
}
.PGA-skin a.btn:not([href]):not([tabindex]),
.PGA-skin a.btn:not([href]):not([tabindex]):focus,
.PGA-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.PGA-skin table.table a.btn.btn-primary {
  color: #fff;
}
.PGA-skin .btn-secondary {
  background-color: #d53032 !important;
  color: #fff;
}
.PGA-skin .btn-secondary:hover {
  background-color: #d94547;
  color: #fff;
}
.PGA-skin .btn-secondary:focus, .PGA-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.PGA-skin .btn-secondary:focus, .PGA-skin .btn-secondary:active, .PGA-skin .btn-secondary.active {
  background-color: #841b1c;
}
.PGA-skin .btn-secondary.dropdown-toggle {
  background-color: #d53032 !important;
}
.PGA-skin .btn-secondary.dropdown-toggle:hover, .PGA-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #d94547 !important;
}
.PGA-skin .btn-secondary:not([disabled]):not(.disabled):active, .PGA-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .PGA-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #841b1c !important;
}
.PGA-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .PGA-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .PGA-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.PGA-skin .secondary-ic {
  color: #d53032 !important;
}
.PGA-skin .secondary-ic:hover, .PGA-skin .secondary-ic:focus {
  color: #d53032;
}
.PGA-skin a.btn:not([href]):not([tabindex]),
.PGA-skin a.btn:not([href]):not([tabindex]):focus,
.PGA-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.PGA-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.PGA-skin .btn-default {
  background-color: #52585c !important;
  color: #fff;
}
.PGA-skin .btn-default:hover {
  background-color: #5e6569;
  color: #fff;
}
.PGA-skin .btn-default:focus, .PGA-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.PGA-skin .btn-default:focus, .PGA-skin .btn-default:active, .PGA-skin .btn-default.active {
  background-color: #222426;
}
.PGA-skin .btn-default.dropdown-toggle {
  background-color: #52585c !important;
}
.PGA-skin .btn-default.dropdown-toggle:hover, .PGA-skin .btn-default.dropdown-toggle:focus {
  background-color: #5e6569 !important;
}
.PGA-skin .btn-default:not([disabled]):not(.disabled):active, .PGA-skin .btn-default:not([disabled]):not(.disabled).active, .show > .PGA-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #222426 !important;
}
.PGA-skin .btn-default:not([disabled]):not(.disabled):active:focus, .PGA-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .PGA-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.PGA-skin .default-ic {
  color: #52585c !important;
}
.PGA-skin .default-ic:hover, .PGA-skin .default-ic:focus {
  color: #52585c;
}
.PGA-skin a.btn:not([href]):not([tabindex]),
.PGA-skin a.btn:not([href]):not([tabindex]):focus,
.PGA-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.PGA-skin table.table a.btn.btn-default {
  color: #fff;
}
.PGA-skin .btn-outline-primary {
  border: 2px solid #4682b4 !important;
  background-color: transparent !important;
  color: #4682b4 !important;
}
.PGA-skin .btn-outline-primary:hover, .PGA-skin .btn-outline-primary:focus, .PGA-skin .btn-outline-primary:active, .PGA-skin .btn-outline-primary:active:focus, .PGA-skin .btn-outline-primary.active {
  border-color: #4682b4 !important;
  background-color: transparent !important;
  color: #4682b4 !important;
}
.PGA-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .PGA-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .PGA-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #4682b4 !important;
}
.PGA-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .PGA-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .PGA-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.PGA-skin .btn-outline-secondary {
  border: 2px solid #d53032 !important;
  background-color: transparent !important;
  color: #d53032 !important;
}
.PGA-skin .btn-outline-secondary:hover, .PGA-skin .btn-outline-secondary:focus, .PGA-skin .btn-outline-secondary:active, .PGA-skin .btn-outline-secondary:active:focus, .PGA-skin .btn-outline-secondary.active {
  border-color: #d53032 !important;
  background-color: transparent !important;
  color: #d53032 !important;
}
.PGA-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .PGA-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .PGA-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #d53032 !important;
}
.PGA-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .PGA-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .PGA-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.PGA-skin .btn-outline-default {
  border: 2px solid #52585c !important;
  background-color: transparent !important;
  color: #52585c !important;
}
.PGA-skin .btn-outline-default:hover, .PGA-skin .btn-outline-default:focus, .PGA-skin .btn-outline-default:active, .PGA-skin .btn-outline-default:active:focus, .PGA-skin .btn-outline-default.active {
  border-color: #52585c !important;
  background-color: transparent !important;
  color: #52585c !important;
}
.PGA-skin .btn-outline-default:not([disabled]):not(.disabled):active, .PGA-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .PGA-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #52585c !important;
}
.PGA-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .PGA-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .PGA-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.PGA-skin .card .btn-action {
  background: #52585c;
}
.PGA-skin .card .btn-action:hover, .PGA-skin .card .btn-action:focus {
  background-color: #5e6569 !important;
}
.PGA-skin .card .btn-action.active {
  background-color: #222426 !important;
}
.PGA-skin .md-outline input[type=text]:focus:not([readonly]),
.PGA-skin .md-outline input[type=password]:focus:not([readonly]),
.PGA-skin .md-outline input[type=email]:focus:not([readonly]),
.PGA-skin .md-outline input[type=url]:focus:not([readonly]),
.PGA-skin .md-outline input[type=time]:focus:not([readonly]),
.PGA-skin .md-outline input[type=date]:focus:not([readonly]),
.PGA-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.PGA-skin .md-outline input[type=tel]:focus:not([readonly]),
.PGA-skin .md-outline input[type=number]:focus:not([readonly]),
.PGA-skin .md-outline input[type=search-md]:focus:not([readonly]),
.PGA-skin .md-outline input[type=search]:focus:not([readonly]),
.PGA-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #4682b4;
  box-shadow: inset 0px 0px 0px 1px #4682b4;
}
.PGA-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.PGA-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.PGA-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #4682b4;
}
.PGA-skin .md-bg input[type=text],
.PGA-skin .md-bg input[type=password],
.PGA-skin .md-bg input[type=email],
.PGA-skin .md-bg input[type=url],
.PGA-skin .md-bg input[type=time],
.PGA-skin .md-bg input[type=date],
.PGA-skin .md-bg input[type=datetime-local],
.PGA-skin .md-bg input[type=tel],
.PGA-skin .md-bg input[type=number],
.PGA-skin .md-bg input[type=search-md],
.PGA-skin .md-bg input[type=search],
.PGA-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #4682b4, #4682b4), linear-gradient(to bottom, #ced4da, #ced4da);
}
.PGA-skin input[type=email]:focus:not([readonly]),
.PGA-skin input[type=text]:focus:not([readonly]),
.PGA-skin input[type=password]:focus:not([readonly]),
.PGA-skin input[type=number]:focus:not([readonly]),
.PGA-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #4682b4;
  box-shadow: 0 1px 0 0 #4682b4;
}
.PGA-skin input[type=email]:focus:not([readonly]) + label,
.PGA-skin input[type=text]:focus:not([readonly]) + label,
.PGA-skin input[type=password]:focus:not([readonly]) + label,
.PGA-skin input[type=number]:focus:not([readonly]) + label,
.PGA-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #4682b4;
}
.PGA-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #4682b4;
  border-bottom: 2px solid #4682b4;
}
.PGA-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.PGA-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #4682b4;
  border-color: #4682b4;
}
.PGA-skin .md-form .prefix.active {
  color: #4682b4;
}
.PGA-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #4682b4 !important;
}
.PGA-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #4682b4;
  box-shadow: inset 0px 0px 0px 1px #4682b4;
}
.PGA-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #4682b4;
}
.PGA-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.PGA-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.PGA-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #4682b4 !important;
}
.PGA-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.PGA-skin .top-nav-collapse {
  background-color: #fff;
}
.PGA-skin .carousel-multi-item .controls-top > a,
.PGA-skin .carousel-multi-item .carousel-indicators li,
.PGA-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #4682b4;
}
.PGA-skin .form-header,
.PGA-skin .card-header {
  background-color: #4b87b9;
}
.PGA-skin .spinner-primary-color,
.PGA-skin .spinner-primary-color-only {
  border-color: #4682b4;
}
.PGA-skin .pagination-primary-color .page-item.active .page-link,
.PGA-skin .pagination-primary-color .page-item.active .page-link:focus,
.PGA-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #4682b4;
}
.PGA-skin .pagination-primary-color .page-link {
  color: #4682b4;
}
.PGA-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.white-skin .pills-primary .nav-link.active {
  background-color: #4285f4 !important;
}
.white-skin .pills-secondary .nav-link.active {
  background-color: #fc685f !important;
}
.white-skin .pills-default .nav-link.active {
  background-color: #454545 !important;
}
.white-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.white-skin .navbar-nav .nav-item .dropdown-menu a:hover, .white-skin .navbar-nav .nav-item .dropdown-menu a:focus, .white-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #2a75f3 !important;
}
.white-skin .navbar-nav .nav-item a {
  color: #424242;
}
.white-skin .dropdown-menu a {
  color: #000;
}
.white-skin .dropdown-menu a:hover, .white-skin .dropdown-menu a:focus, .white-skin .dropdown-menu a:active {
  background-color: #2a75f3 !important;
}
.white-skin .range-cloud {
  background-color: #4285f4 !important;
}
.white-skin .range-cloud:after {
  border-top-color: #4285f4 !important;
}
.white-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #4285f4;
}
.white-skin .range-field input[type=range]::-moz-range-thumb {
  background: #4285f4;
}
.white-skin .range-field input[type=range]::-ms-thumb {
  background: #4285f4;
}
.white-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.white-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.white-skin .side-nav .sidenav-bg {
  position: fixed;
}
.white-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.white-skin .side-nav .social a:hover .fa {
  color: #4285f4;
  transition: 0.3s;
}
.white-skin .side-nav .collapsible .card .card-header a h5 {
  color: #424242;
}
.white-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.white-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: #4285f4;
  color: #4285f4;
  background-color: transparent;
}
.white-skin .side-nav .collapsible .card.active > mdb-accordion-item-head .card-header a h5 {
  color: #4285f4;
  background-color: transparent;
}
.white-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #424242;
}
.white-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #424242;
}
.white-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(0, 0, 0, 0.05);
}
.white-skin .side-nav .collapsible .card-body li a {
  color: #424242;
}
.white-skin .side-nav .collapsible .card-body li a:hover {
  color: #4285f4;
}
.white-skin .side-nav .collapsible .card-body li a.active {
  color: #4285f4;
}

.black-skin .pills-primary .nav-link.active {
  background-color: #2ad1a3 !important;
}
.black-skin .pills-secondary .nav-link.active {
  background-color: #0f478a !important;
}
.black-skin .pills-default .nav-link.active {
  background-color: #222222 !important;
}
.black-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.black-skin .navbar-nav .nav-item .dropdown-menu a:hover, .black-skin .navbar-nav .nav-item .dropdown-menu a:focus, .black-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #26bc92 !important;
}
.black-skin .dropdown-menu a {
  color: #000;
}
.black-skin .dropdown-menu a:hover, .black-skin .dropdown-menu a:focus, .black-skin .dropdown-menu a:active {
  background-color: #26bc92 !important;
}
.black-skin .range-cloud {
  background-color: #2ad1a3 !important;
}
.black-skin .range-cloud:after {
  border-top-color: #2ad1a3 !important;
}
.black-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #2ad1a3;
}
.black-skin .range-field input[type=range]::-moz-range-thumb {
  background: #2ad1a3;
}
.black-skin .range-field input[type=range]::-ms-thumb {
  background: #2ad1a3;
}
.black-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.black-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.black-skin .side-nav .sidenav-bg {
  position: fixed;
}
.black-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.black-skin .side-nav .social a:hover .fa {
  color: #2ad1a3;
  transition: 0.3s;
}
.black-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.black-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.black-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.black-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.black-skin .side-nav .collapsible .card-body li a:hover {
  color: #2ad1a3;
}
.black-skin .side-nav .collapsible .card-body li a.active {
  color: #2ad1a3;
}

.cyan-skin .pills-primary .nav-link.active {
  background-color: #ffa742 !important;
}
.cyan-skin .pills-secondary .nav-link.active {
  background-color: #45b0c0 !important;
}
.cyan-skin .pills-default .nav-link.active {
  background-color: #114861 !important;
}
.cyan-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.cyan-skin .navbar-nav .nav-item .dropdown-menu a:hover, .cyan-skin .navbar-nav .nav-item .dropdown-menu a:focus, .cyan-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #eea82f !important;
}
.cyan-skin .dropdown-menu a {
  color: #000;
}
.cyan-skin .dropdown-menu a:hover, .cyan-skin .dropdown-menu a:focus, .cyan-skin .dropdown-menu a:active {
  background-color: #eea82f !important;
}
.cyan-skin .range-cloud {
  background-color: #4fada7 !important;
}
.cyan-skin .range-cloud:after {
  border-top-color: #4fada7 !important;
}
.cyan-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #4fada7;
}
.cyan-skin .range-field input[type=range]::-moz-range-thumb {
  background: #4fada7;
}
.cyan-skin .range-field input[type=range]::-ms-thumb {
  background: #4fada7;
}
.cyan-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.cyan-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.cyan-skin .side-nav .sidenav-bg {
  position: fixed;
}
.cyan-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.cyan-skin .side-nav .social a:hover .fa {
  color: #f0b247;
  transition: 0.3s;
}
.cyan-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.cyan-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.cyan-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.cyan-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.cyan-skin .side-nav .collapsible .card-body li a:hover {
  color: #ffa742;
}
.cyan-skin .side-nav .collapsible .card-body li a.active {
  color: #ffa742;
}

.mdb-skin .pills-primary .nav-link.active {
  background-color: #33b5e5 !important;
}
.mdb-skin .pills-secondary .nav-link.active {
  background-color: #0e3d67 !important;
}
.mdb-skin .pills-default .nav-link.active {
  background-color: #1a6398 !important;
}
.mdb-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.mdb-skin .navbar-nav .nav-item .dropdown-menu a:hover, .mdb-skin .navbar-nav .nav-item .dropdown-menu a:focus, .mdb-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #1dade2 !important;
}
.mdb-skin .dropdown-menu a {
  color: #000;
}
.mdb-skin .dropdown-menu a:hover, .mdb-skin .dropdown-menu a:focus, .mdb-skin .dropdown-menu a:active {
  background-color: #1dade2 !important;
}
.mdb-skin .range-cloud {
  background-color: #33b5e5 !important;
}
.mdb-skin .range-cloud:after {
  border-top-color: #33b5e5 !important;
}
.mdb-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #33b5e5;
}
.mdb-skin .range-field input[type=range]::-moz-range-thumb {
  background: #33b5e5;
}
.mdb-skin .range-field input[type=range]::-ms-thumb {
  background: #33b5e5;
}
.mdb-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.mdb-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.mdb-skin .side-nav .sidenav-bg {
  position: fixed;
}
.mdb-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.mdb-skin .side-nav .social a:hover .fa {
  color: #33b5e5;
  transition: 0.3s;
}
.mdb-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.mdb-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.mdb-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.mdb-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.mdb-skin .side-nav .collapsible .card-body li a:hover {
  color: #67d5ff;
}
.mdb-skin .side-nav .collapsible .card-body li a.active {
  color: #67d5ff;
}

.deep-purple-skin .pills-primary .nav-link.active {
  background-color: #ac85f0 !important;
}
.deep-purple-skin .pills-secondary .nav-link.active {
  background-color: #6e4ca3 !important;
}
.deep-purple-skin .pills-default .nav-link.active {
  background-color: #372e5f !important;
}
.deep-purple-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.deep-purple-skin .navbar-nav .nav-item .dropdown-menu a:hover, .deep-purple-skin .navbar-nav .nav-item .dropdown-menu a:focus, .deep-purple-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #9d6eed !important;
}
.deep-purple-skin .dropdown-menu a {
  color: #000;
}
.deep-purple-skin .dropdown-menu a:hover, .deep-purple-skin .dropdown-menu a:focus, .deep-purple-skin .dropdown-menu a:active {
  background-color: #9d6eed !important;
}
.deep-purple-skin .range-cloud {
  background-color: #d0637c !important;
}
.deep-purple-skin .range-cloud:after {
  border-top-color: #d0637c !important;
}
.deep-purple-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #d0637c;
}
.deep-purple-skin .range-field input[type=range]::-moz-range-thumb {
  background: #d0637c;
}
.deep-purple-skin .range-field input[type=range]::-ms-thumb {
  background: #d0637c;
}
.deep-purple-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.deep-purple-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.deep-purple-skin .side-nav .sidenav-bg {
  position: fixed;
}
.deep-purple-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.deep-purple-skin .side-nav .social a:hover .fa {
  color: #ac85f0;
  transition: 0.3s;
}
.deep-purple-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible .card-body li a:hover {
  color: #c9abfc;
}
.deep-purple-skin .side-nav .collapsible .card-body li a.active {
  color: #c9abfc;
}

.navy-blue-skin .pills-primary .nav-link.active {
  background-color: #40c6c6 !important;
}
.navy-blue-skin .pills-secondary .nav-link.active {
  background-color: #7e51b4 !important;
}
.navy-blue-skin .pills-default .nav-link.active {
  background-color: #293756 !important;
}
.navy-blue-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.navy-blue-skin .navbar-nav .nav-item .dropdown-menu a:hover, .navy-blue-skin .navbar-nav .nav-item .dropdown-menu a:focus, .navy-blue-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #36b6b6 !important;
}
.navy-blue-skin .dropdown-menu a {
  color: #000;
}
.navy-blue-skin .dropdown-menu a:hover, .navy-blue-skin .dropdown-menu a:focus, .navy-blue-skin .dropdown-menu a:active {
  background-color: #36b6b6 !important;
}
.navy-blue-skin .range-cloud {
  background-color: #309e9e !important;
}
.navy-blue-skin .range-cloud:after {
  border-top-color: #309e9e !important;
}
.navy-blue-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #309e9e;
}
.navy-blue-skin .range-field input[type=range]::-moz-range-thumb {
  background: #309e9e;
}
.navy-blue-skin .range-field input[type=range]::-ms-thumb {
  background: #309e9e;
}
.navy-blue-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.navy-blue-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.navy-blue-skin .side-nav .sidenav-bg {
  position: fixed;
}
.navy-blue-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.navy-blue-skin .side-nav .social a:hover .fa {
  color: #40c6c6;
  transition: 0.3s;
}
.navy-blue-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible .card-body li a:hover {
  color: #40c6c6;
}
.navy-blue-skin .side-nav .collapsible .card-body li a.active {
  color: #40c6c6;
}

.pink-skin .pills-primary .nav-link.active {
  background-color: #601f39 !important;
}
.pink-skin .pills-secondary .nav-link.active {
  background-color: #e7649d !important;
}
.pink-skin .pills-default .nav-link.active {
  background-color: #535466 !important;
}
.pink-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.pink-skin .navbar-nav .nav-item .dropdown-menu a:hover, .pink-skin .navbar-nav .nav-item .dropdown-menu a:focus, .pink-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #9192a2 !important;
}
.pink-skin .dropdown-menu a {
  color: #000;
}
.pink-skin .dropdown-menu a:hover, .pink-skin .dropdown-menu a:focus, .pink-skin .dropdown-menu a:active {
  background-color: #9192a2 !important;
}
.pink-skin .range-cloud {
  background-color: #bd5d70 !important;
}
.pink-skin .range-cloud:after {
  border-top-color: #bd5d70 !important;
}
.pink-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #bd5d70;
}
.pink-skin .range-field input[type=range]::-moz-range-thumb {
  background: #bd5d70;
}
.pink-skin .range-field input[type=range]::-ms-thumb {
  background: #bd5d70;
}
.pink-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.pink-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.pink-skin .side-nav .sidenav-bg {
  position: fixed;
}
.pink-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.pink-skin .side-nav .social a:hover .fa {
  color: #9fa0ae;
  transition: 0.3s;
}
.pink-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.pink-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.pink-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.pink-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.pink-skin .side-nav .collapsible .card-body li a:hover {
  color: #ffb0e6;
}
.pink-skin .side-nav .collapsible .card-body li a.active {
  color: #ffb0e6;
}

.indigo-skin .pills-primary .nav-link.active {
  background-color: #fd668c !important;
}
.indigo-skin .pills-secondary .nav-link.active {
  background-color: #3c61ae !important;
}
.indigo-skin .pills-default .nav-link.active {
  background-color: #9095aa !important;
}
.indigo-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.indigo-skin .navbar-nav .nav-item .dropdown-menu a:hover, .indigo-skin .navbar-nav .nav-item .dropdown-menu a:focus, .indigo-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #fd4d79 !important;
}
.indigo-skin .dropdown-menu a {
  color: #000;
}
.indigo-skin .dropdown-menu a:hover, .indigo-skin .dropdown-menu a:focus, .indigo-skin .dropdown-menu a:active {
  background-color: #fd4d79 !important;
}
.indigo-skin .range-cloud {
  background-color: #7f82c5 !important;
}
.indigo-skin .range-cloud:after {
  border-top-color: #7f82c5 !important;
}
.indigo-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #7f82c5;
}
.indigo-skin .range-field input[type=range]::-moz-range-thumb {
  background: #7f82c5;
}
.indigo-skin .range-field input[type=range]::-ms-thumb {
  background: #7f82c5;
}
.indigo-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.indigo-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.indigo-skin .side-nav .sidenav-bg {
  position: fixed;
}
.indigo-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.indigo-skin .side-nav .social a:hover .fa {
  color: #fd668c;
  transition: 0.3s;
}
.indigo-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.indigo-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.indigo-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.indigo-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.indigo-skin .side-nav .collapsible .card-body li a:hover {
  color: #ff89ac;
}
.indigo-skin .side-nav .collapsible .card-body li a.active {
  color: #ff89ac;
}

.light-blue-skin .pills-primary .nav-link.active {
  background-color: #ff4a67 !important;
}
.light-blue-skin .pills-secondary .nav-link.active {
  background-color: #64c3f0 !important;
}
.light-blue-skin .pills-default .nav-link.active {
  background-color: #2d486a !important;
}
.light-blue-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.light-blue-skin .navbar-nav .nav-item .dropdown-menu a:hover, .light-blue-skin .navbar-nav .nav-item .dropdown-menu a:focus, .light-blue-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #a5b6be !important;
}
.light-blue-skin .dropdown-menu a {
  color: #000;
}
.light-blue-skin .dropdown-menu a:hover, .light-blue-skin .dropdown-menu a:focus, .light-blue-skin .dropdown-menu a:active {
  background-color: #a5b6be !important;
}
.light-blue-skin .range-cloud {
  background-color: #3d799c !important;
}
.light-blue-skin .range-cloud:after {
  border-top-color: #3d799c !important;
}
.light-blue-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #3d799c;
}
.light-blue-skin .range-field input[type=range]::-moz-range-thumb {
  background: #3d799c;
}
.light-blue-skin .range-field input[type=range]::-ms-thumb {
  background: #3d799c;
}
.light-blue-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.light-blue-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.light-blue-skin .side-nav .sidenav-bg {
  position: fixed;
}
.light-blue-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.light-blue-skin .side-nav .social a:hover .fa {
  color: #b4c2c9;
  transition: 0.3s;
}
.light-blue-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible .card-body li a:hover {
  color: #aadeff;
}
.light-blue-skin .side-nav .collapsible .card-body li a.active {
  color: #aadeff;
}

.grey-skin .pills-primary .nav-link.active {
  background-color: #46aded !important;
}
.grey-skin .pills-secondary .nav-link.active {
  background-color: #7d8488 !important;
}
.grey-skin .pills-default .nav-link.active {
  background-color: #353637 !important;
}
.grey-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.grey-skin .navbar-nav .nav-item .dropdown-menu a:hover, .grey-skin .navbar-nav .nav-item .dropdown-menu a:focus, .grey-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #5eb7ee !important;
}
.grey-skin .dropdown-menu a {
  color: #000;
}
.grey-skin .dropdown-menu a:hover, .grey-skin .dropdown-menu a:focus, .grey-skin .dropdown-menu a:active {
  background-color: #5eb7ee !important;
}
.grey-skin .range-cloud {
  background-color: #2b2b2b !important;
}
.grey-skin .range-cloud:after {
  border-top-color: #2b2b2b !important;
}
.grey-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #2b2b2b;
}
.grey-skin .range-field input[type=range]::-moz-range-thumb {
  background: #2b2b2b;
}
.grey-skin .range-field input[type=range]::-ms-thumb {
  background: #2b2b2b;
}
.grey-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.grey-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.grey-skin .side-nav .sidenav-bg {
  position: fixed;
}
.grey-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.grey-skin .side-nav .social a:hover .fa {
  color: #75c1f0;
  transition: 0.3s;
}
.grey-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.grey-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.grey-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.grey-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.grey-skin .side-nav .collapsible .card-body li a:hover {
  color: #afdffc;
}
.grey-skin .side-nav .collapsible .card-body li a.active {
  color: #afdffc;
}

.PGA-skin .pills-primary .nav-link.active {
  background-color: #4682b4 !important;
}
.PGA-skin .pills-secondary .nav-link.active {
  background-color: #d53032 !important;
}
.PGA-skin .pills-default .nav-link.active {
  background-color: #52585c !important;
}
.PGA-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.PGA-skin .navbar-nav .nav-item .dropdown-menu a:hover, .PGA-skin .navbar-nav .nav-item .dropdown-menu a:focus, .PGA-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #3f75a2 !important;
}
.PGA-skin .dropdown-menu a {
  color: #000;
}
.PGA-skin .dropdown-menu a:hover, .PGA-skin .dropdown-menu a:focus, .PGA-skin .dropdown-menu a:active {
  background-color: #3f75a2 !important;
}
.PGA-skin .range-cloud {
  background-color: #4682b4 !important;
}
.PGA-skin .range-cloud:after {
  border-top-color: #4682b4 !important;
}
.PGA-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #4682b4;
}
.PGA-skin .range-field input[type=range]::-moz-range-thumb {
  background: #4682b4;
}
.PGA-skin .range-field input[type=range]::-ms-thumb {
  background: #4682b4;
}
.PGA-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.PGA-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.PGA-skin .side-nav .sidenav-bg {
  position: fixed;
}
.PGA-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.PGA-skin .side-nav .social a:hover .fa {
  color: #4682b4;
  transition: 0.3s;
}
.PGA-skin .side-nav .collapsible .card .card-header a h5 {
  color: #2d5373;
}
.PGA-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: #fff;
}
.PGA-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: #fff;
}
.PGA-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #2d5373;
}
.PGA-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #2d5373;
}
.PGA-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: #fff;
}
.PGA-skin .side-nav .collapsible .card-body li a {
  color: #2d5373;
}
.PGA-skin .side-nav .collapsible .card-body li a:hover {
  color: #fff;
}
.PGA-skin .side-nav .collapsible .card-body li a.active {
  color: #fff;
}

.timeline-main .stepper.timeline-images li a {
  padding: 0px;
  left: 50%;
}
.timeline-main .stepper.timeline-images li a .circle {
  margin-top: 0.9rem;
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 1em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -12px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.timeline-images li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.timeline-images li .step-content.hoverable {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.timeline-main .stepper.timeline-images li .step-content.hoverable:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.timeline-main .stepper.timeline-images li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.timeline-images li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.stepper-vertical.timeline-images li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline-simple li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 2px;
  left: 50%;
  top: 54px;
  height: 100%;
  margin-left: -1.5px;
}
.timeline-main .stepper.stepper-vertical.timeline-images li:not(:last-child):after {
  background-color: #26c6da;
}
.timeline-main .stepper.stepper-vertical.timeline-simple li:not(:last-child):after {
  background-color: #9e9e9e;
}
.timeline-main .stepper.timeline-simple li a {
  padding: 0px;
  left: 50%;
}
.timeline-main .stepper.timeline-simple li a .circle {
  margin-top: 0.9rem;
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -12px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.timeline-simple li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.timeline-simple li .step-content:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline-simple li .step-content:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline-simple li .step-content.hoverable {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.timeline-main .stepper.timeline-simple li .step-content.hoverable:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.timeline-main .stepper.timeline-simple li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.timeline-simple li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.timeline-simple li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.timeline-simple li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.timeline li a {
  padding: 0px 24px;
  left: 50%;
}
.timeline-main .stepper.timeline li a .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -50px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.timeline li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.timeline li .step-content:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline li .step-content:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.timeline li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.timeline li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.timeline li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 3px;
  background-color: #e0e0e0;
  left: 50%;
  top: 65px;
  margin-left: -1.5px;
}
.timeline-main .stepper.colorful-timeline li a {
  padding: 0px 24px;
  left: 50%;
}
.timeline-main .stepper.colorful-timeline li a .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -50px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.colorful-timeline li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.colorful-timeline li .step-content:before {
  position: absolute;
  top: 1rem;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #d32f2f;
  border-right: 0 solid #d32f2f;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline-main .stepper.colorful-timeline li .step-content:after {
  position: absolute;
  top: 1rem;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #d32f2f;
  border-right: 0 solid #d32f2f;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline-main .stepper.colorful-timeline li .step-content .timeline-header {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.timeline-main .stepper.colorful-timeline li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.colorful-timeline li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.colorful-timeline li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.colorful-timeline li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical.colorful-timeline li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 3px;
  background-color: #d32f2f;
  left: 50%;
  top: 65px;
  margin-left: -1.5px;
}
.timeline-main .stepper.timeline-animated .more-padding {
  padding-right: 100px;
  padding-left: 100px;
}
.timeline-main .stepper.timeline-animated li {
  transition: all 0.7s ease-in-out;
}
.timeline-main .stepper.timeline-animated li a {
  padding: 0px 24px;
  left: 50%;
}
.timeline-main .stepper.timeline-animated li a .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -50px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.timeline-animated li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
.timeline-main .stepper.timeline-animated li .step-content:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline-animated li .step-content:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline-main .stepper.timeline-animated li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.timeline-animated li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.timeline-animated li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.timeline-animated li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 3px;
  background-color: #e0e0e0;
  left: 50%;
  top: 65px;
  margin-left: -1.5px;
}

@media (max-width: 1025px) {
  .timeline-main .stepper.timeline-images li, .timeline-main .stepper.timeline li, .timeline-main .stepper.colorful-timeline li, .timeline-main .stepper.timeline-animated li, .timeline-main .stepper.timeline-simple li {
    align-items: flex-end;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.timeline-images li a, .timeline-main .stepper.timeline-simple li a, .timeline-main .stepper.timeline li a, .timeline-main .stepper.colorful-timeline li a, .timeline-main .stepper.timeline-animated li a {
    left: 6%;
  }
  .timeline-main .stepper.timeline-images li .step-content, .timeline-main .stepper.timeline-simple li .step-content, .timeline-main .stepper.timeline li .step-content, .timeline-main .stepper.colorful-timeline li .step-content, .timeline-main .stepper.timeline-animated li .step-content {
    width: 80%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
  .timeline-main .stepper.timeline-simple li .step-content:before, .timeline-main .stepper.timeline li .step-content:before, .timeline-main .stepper.colorful-timeline li .step-content:before, .timeline-main .stepper.timeline-animated li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  .timeline-main .stepper.timeline-simple li .step-content:after, .timeline-main .stepper.timeline li .step-content:after, .timeline-main .stepper.colorful-timeline li .step-content:after, .timeline-main .stepper.timeline-animated li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
  .timeline-main .stepper.stepper-vertical.timeline-simple li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline-images li:not(:last-child):after, .timeline-main .stepper.colorful-timeline li a, .timeline-main .stepper.stepper-vertical.colorful-timeline li:not(:last-child):after, .timeline-main .stepper.timeline-animated li a, .timeline-main .stepper.stepper-vertical li:not(:last-child):after {
    left: 6%;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.timeline-simple li .step-content:before, .timeline-main .stepper.timeline-animated li .step-content:before, .timeline-main .stepper.timeline li .step-content:before, .timeline-main .stepper.colorful-timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  .timeline-main .stepper.timeline-simple li .step-content:after, .timeline-main .stepper.timeline-animated li .step-content:after, .timeline-main .stepper.timeline li .step-content:after, .timeline-main .stepper.colorful-timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
  .timeline-main .stepper.timeline-images li a, .timeline-main .stepper.timeline li a, .timeline-main .stepper.timeline-simple li a, .timeline-main .stepper.timeline-animated li a, .timeline-main .stepper.colorful-timeline li a {
    left: 6%;
  }
  .timeline-main .stepper.timeline-images li .step-content, .timeline-main .stepper.timeline li .step-content, .timeline-main .stepper.timeline-simple li .step-content, .timeline-main .stepper.timeline-animated li .step-content, .timeline-main .stepper.colorful-timeline li .step-content {
    width: 85%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
  .timeline-main .stepper.stepper-vertical.timeline-images li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline-simple li:not(:last-child):after, .timeline-main .stepper.stepper-vertical li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.colorful-timeline li:not(:last-child):after {
    left: 6%;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li a {
  padding: 0px 24px;
  left: 50%;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li a {
    left: 6%;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li a {
    left: 6%;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li a .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -50px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.stepper-vertical.timeline li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content {
    width: 80%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content {
    width: 85%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li .step-content:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li .step-content:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted {
  align-items: flex-end;
}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted .step-content {
  float: right;
}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-main .stepper.stepper-vertical.timeline li:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 3px;
  background-color: #e0e0e0;
  left: 50%;
  top: 65px;
  margin-left: -1.5px;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline li:not(:last-child):after {
    left: 6%;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li:not(:last-child):after {
    left: 6%;
  }
}
@media (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline li {
    align-items: flex-end;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:before {
  top: 1rem;
  border-left: 15px solid #d32f2f;
  border-right: 0 solid #d32f2f;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:after {
  top: 1rem;
  border-left: 14px solid #d32f2f;
  border-right: 0 solid #d32f2f;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content .timeline-header {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li:not(:last-child):after {
  background-color: #d32f2f;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-animated .more-padding {
  padding-right: 100px;
  padding-left: 100px;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-animated li {
  transition: all 0.7s ease-in-out;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li a {
  padding: 0px;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li a .circle {
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-left: -12px;
  background-color: #ccc;
  z-index: 2;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content {
  width: 45%;
  float: left;
  border-radius: 2px;
  position: relative;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content {
    width: 80%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content {
    width: 85%;
    left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    float: right;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:before {
  position: absolute;
  top: 15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #e0e0e0;
  border-right: 0 solid #e0e0e0;
  border-bottom: 15px solid transparent;
  content: " ";
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:after {
  position: absolute;
  top: 15px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
@media (min-width: 451px) and (max-width: 1025px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content.hoverable {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content.hoverable:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li:not(:last-child):after {
  width: 2px;
  background-color: #9e9e9e;
  top: 32px;
  height: 100%;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li.timeline-inverted .step-content:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li.timeline-inverted .step-content:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-images li a .circle {
  font-size: 1rem;
}
.timeline-main .stepper.stepper-vertical.timeline.timeline-images li:not(:last-child):after {
  background-color: #26c6da;
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-basic li a, .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li a, .timeline-main .stepper.stepper-vertical.timeline.timeline-animated li a {
    left: 8%;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-basic li a .circle, .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li a .circle, .timeline-main .stepper.stepper-vertical.timeline.timeline-animated li a .circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 0.9em;
    top: 26px;
    margin-left: -35px;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-basic li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline.timeline-animated li:not(:last-child):after {
    left: 9%;
    height: 100%;
    top: 55px;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li a, .timeline-main .stepper.stepper-vertical.timeline.timeline-images li a {
    left: 10%;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li a .circle, .timeline-main .stepper.stepper-vertical.timeline.timeline-images li a .circle {
    top: 18px;
  }
}
@media (max-width: 450px) {
  .timeline-main .stepper.stepper-vertical.timeline.timeline-simple li:not(:last-child):after, .timeline-main .stepper.stepper-vertical.timeline.timeline-images li:not(:last-child):after {
    left: 10%;
  }
}
.timeline-main .timeline-light {
  list-style: none;
  padding-left: 1em;
  line-height: 1.5em;
  font-size: 20px;
}
.timeline-main .timeline-light .timeline-light-item {
  position: relative;
}
.timeline-main .timeline-light .timeline-light-item .timeline-light-item-tail {
  position: absolute;
  border-left: 2px solid #e8e8e8;
  height: 100%;
  width: 0;
  bottom: 0;
  left: 14px;
}
.timeline-main .timeline-light .timeline-light-item .timeline-light-item-head {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 15px;
  bottom: 4px;
  transform: translate(-50%, 0);
  border: 2px solid #1890ff;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
}
.timeline-main .timeline-light .timeline-light-item .timeline-light-item-content {
  margin-left: 30px;
  padding-top: 0.2em;
  line-height: 3em;
  height: 2em;
}
.timeline-main .timeline-light .timeline-light-item-primary .timeline-light-item-head {
  border-color: #4285f4;
}
.timeline-main .timeline-light .timeline-light-item-danger .timeline-light-item-head {
  border-color: #ff3547;
}
.timeline-main .timeline-light .timeline-light-item-warning .timeline-light-item-head {
  border-color: #ffbb33;
}
.timeline-main .timeline-light .timeline-light-item-success .timeline-light-item-head {
  border-color: #00c851;
}
.timeline-main .timeline-light .timeline-light-item-info .timeline-light-item-head {
  border-color: #33b5e5;
}
.timeline-main .timeline-light .timeline-light-item-default .timeline-light-item-head {
  border-color: #2bbbad;
}
.timeline-main .timeline-light .timeline-light-item-secondary .timeline-light-item-head {
  border-color: #aa66cc;
}
.timeline-main .timeline-light .timeline-light-item-elegant .timeline-light-item-head {
  border-color: #2e2e2e;
}
.timeline-main .timeline-light .timeline-light-item-unique .timeline-light-item-head {
  border-color: #880e4f;
}
.timeline-main .timeline-light .timeline-light-item-dark-green .timeline-light-item-head {
  border-color: #388e3c;
}
.timeline-main .timeline-light .timeline-light-item-mdb-color .timeline-light-item-head {
  border-color: #59698d;
}
.timeline-main .timeline-light .timeline-light-item-red .timeline-light-item-head {
  border-color: #d32f2f;
}
.timeline-main .timeline-light .timeline-light-item-pink .timeline-light-item-head {
  border-color: #ec407a;
}
.timeline-main .timeline-light .timeline-light-item-purple .timeline-light-item-head {
  border-color: #8e24aa;
}
.timeline-main .timeline-light .timeline-light-item-deep-purple .timeline-light-item-head {
  border-color: #512da8;
}
.timeline-main .timeline-light .timeline-light-item-indigo .timeline-light-item-head {
  border-color: #3f51b5;
}
.timeline-main .timeline-light .timeline-light-item-blue .timeline-light-item-head {
  border-color: #1976d2;
}
.timeline-main .timeline-light .timeline-light-item-light-blue .timeline-light-item-head {
  border-color: #82b1ff;
}
.timeline-main .timeline-light .timeline-light-item-cyan .timeline-light-item-head {
  border-color: #00bcd4;
}
.timeline-main .timeline-light .timeline-light-item-teal .timeline-light-item-head {
  border-color: #00796b;
}
.timeline-main .timeline-light .timeline-light-item-green .timeline-light-item-head {
  border-color: #388e3c;
}
.timeline-main .timeline-light .timeline-light-item-light-green .timeline-light-item-head {
  border-color: #8bc34a;
}
.timeline-main .timeline-light .timeline-light-item-lime .timeline-light-item-head {
  border-color: #afb42b;
}
.timeline-main .timeline-light .timeline-light-item-yellow .timeline-light-item-head {
  border-color: #fbc02d;
}
.timeline-main .timeline-light .timeline-light-item-amber .timeline-light-item-head {
  border-color: #ffa000;
}
.timeline-main .timeline-light .timeline-light-item-orange .timeline-light-item-head {
  border-color: #f57c00;
}
.timeline-main .timeline-light .timeline-light-item-deep-orange .timeline-light-item-head {
  border-color: #ff7043;
}
.timeline-main .timeline-light .timeline-light-item-brown .timeline-light-item-head {
  border-color: #795548;
}
.timeline-main .timeline-light .timeline-light-item-grey .timeline-light-item-head {
  border-color: #616161;
}
.timeline-main .timeline-light .timeline-light-item-blue-grey .timeline-light-item-head {
  border-color: #78909c;
}
.timeline-main .timeline-light .timeline-light-item-dark .timeline-light-item-head {
  border-color: #212121;
}
.timeline-main .timeline-light .timeline-light-item-light .timeline-light-item-head {
  border-color: #e0e0e0;
}
.timeline-main .timeline-light .timeline-light-item-white .timeline-light-item-head {
  border-color: #fff;
}
.timeline-main .timeline-light .timeline-light-item-black .timeline-light-item-head {
  border-color: #000;
}
.timeline-main .timeline-light .timeline-light-item-loading .timeline-light-item-head {
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  animation: tl-v2-loadingCircle 1s 1s infinite linear;
}
.timeline-main .timeline-light .timeline-light-item-icon .timeline-light-item-head {
  border: none;
  width: 20px;
  height: 20px;
  background: transparent;
}
.timeline-main .timeline-light .timeline-light-item-icon .timeline-light-item-tail {
  height: 100%;
  bottom: 15px;
  left: 14px;
}
.timeline-main .timeline-light .timeline-light-item-icon .timeline-light-item-head {
  font-size: 20px;
  top: 45%;
}
.timeline-main .timeline-light .timeline-light-item-icon .timeline-light-item-head i {
  border-radius: 50%;
  background-color: #fff;
}
.timeline-main .timeline-light .timeline-light-item-dashed .timeline-light-item-tail {
  border-left-style: dashed;
}
.timeline-main .timeline-light li:first-child.timeline-light-item .timeline-light-item-tail {
  height: 0;
}
@keyframes tl-v2-loadingCircle {
  0% {
    transform: translate(-50%, 0) rotate(0deg);
  }
  100% {
    transform: translate(-50%, 0) rotate(360deg);
  }
}

.pricing-card {
  text-align: center;
}
.pricing-card ul {
  list-style-type: none;
  padding: 0;
}
.pricing-card .header {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pricing-card .option {
  padding: 2.5rem;
  margin-bottom: 0;
  font-weight: 500;
}
.pricing-card .price {
  position: relative;
  padding-top: 3.94rem;
}
.pricing-card .price .number {
  font-size: 5rem;
  padding: 1.5rem;
  font-weight: 300;
}
.pricing-card .price .number:before {
  content: "$";
  position: absolute;
  font-size: 1.88rem;
  margin-left: -1.2rem;
  margin-top: 0.7rem;
}
.pricing-card .price .number:after {
  content: "/mo";
  position: absolute;
  font-size: 1.88rem;
  margin-top: 3rem;
}
.pricing-card .price .version {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem 0.25rem 0 0;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
}
.pricing-card .striped {
  padding: 1rem;
}
.pricing-card .striped li {
  border-bottom: 1px solid rgba(238, 238, 238, 0.298039);
  margin-bottom: 1rem;
}
.pricing-card .striped.green-striped li {
  border-color: #33c28a;
}
.pricing-card .striped.orange-striped li {
  border-color: #e97d63;
}
.pricing-card .striped.purple-striped li {
  border-color: #963c94;
}
.pricing-card .striped.darker-striped li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.298039);
}
.pricing-card .card-background {
  background-color: #28283f;
  border-radius: 0 0 0.25rem 0.25rem;
}

.card .card-circle {
  border: 2px solid #e0e0e0;
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
}
.card .card-circle .fas,
.card .card-circle .fab,
.card .card-circle .far {
  font-size: 4rem;
}

.collection-card .stripe {
  position: absolute;
  bottom: 3rem;
  width: 100%;
  text-align: center;
  padding: 1.2rem;
}
.collection-card .stripe.dark {
  background-color: rgba(0, 0, 0, 0.7);
}
.collection-card .stripe.dark a p {
  color: #eeeeee;
}
.collection-card .stripe.light {
  background-color: rgba(255, 255, 255, 0.7);
}
.collection-card .stripe.light a p {
  color: #424242;
}
.collection-card .stripe a p {
  padding: 0;
  margin: 0;
  letter-spacing: 0.25rem;
}

.rating {
  color: #ffa000;
  list-style-type: none;
  padding: 0;
}
.rating li {
  display: inline-block;
}

.cart-modal .table,
.cart-modal th {
  text-align: center;
}
.cart-modal .table .total {
  text-transform: uppercase;
  font-weight: 600;
}
.cart-modal .table .fa-times {
  color: #4285f4;
}

.card.card-ecommerce .card-footer {
  background-color: transparent;
}
.card.card-ecommerce .card-footer .discount {
  color: #9e9e9e;
}
.card.card-ecommerce .card-footer a {
  color: #757575;
  transition: 0.4s;
}
.card.card-ecommerce .card-footer a:hover {
  color: #d50000;
  transition: 0.4s;
}
.card.card-ecommerce .card-footer a.active {
  color: #d50000;
}

.product-table .btn.btn-sm {
  padding: 0.3rem 0.6rem;
}